<template>
	<div  class="barPie barPie--radio"> 
		<div class="barPie__ring">
			<label  class="barPie__ring__item" v-for="item in 32" :key="item"
      :style="{transform:'rotate('+11.25*item+'DEG)'}">
        <div class="gaoliang" :style="item<=Math.floor(percentage/3.125)?'background: rgb(236, 144, 137);':''"></div>
      </label>
		</div>
    <div class="progress">
        <el-progress type="circle" :percentage="percentage" :width="50" color="#312F44" :stroke-width="2"></el-progress>
    </div>
	</div> 
</template>

<script>
export default {
  name:'',
  components: {},

  props:{
    percentage:{
        type:Number,
        default(){
            return 0
        }
    }
  },

  data () {
    return{

    }
  },

  computed: {},

  created(){

  },

  methods: {},

}

</script>
<style lang='less' scoped>
.progress{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 48%;
    left: 46%;
    transform: translate(-50%,-54%);
}
.progress /deep/ .el-progress__text{
    font-size: 14px !important;
}
  .right{
      right:0;
  }
  .left{
      left:0;
  }
  /* 鍐呭渾鍦� 缁撴潫 */
  .gaoliang{
    content: '';
    display: block;
    width: 50%;
    height: 30%;
    border-radius: 5px;
    position: absolute;
      top: 0px; 
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 0 4px rgba(0, 0, 0, 0.33) inset;
    -webkit-transition: .3s;
    transition: .3s;
  }
  .barPie--radio {
    width: 80px;
    height: 80px;
    text-align: center;
    font: 700 50px 'Open Sans Condensed', sans-serif;
    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    margin: 0 auto 6px;
  }
  
  .barPie {
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  .barPie__value {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    /* align horizontal */
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    /* align vertical */
    width: 50%;
    height: 50%;
    margin: auto;
    position: relative;
    top: 50%;
    margin-top: -25%;
    z-index: 1;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.8em;
    text-shadow: 0 1px rgba(0, 0, 0, 0.5), 0 2px rgba(0, 0, 0, 0.4), 0 3px 1px rgba(0, 0, 0, 0.2);
  }
  .barPie__value::after {
    content: '%';
    display: inline-block;
    opacity: .3;
    font-size: .5em;
    margin-left: .1em;
  }
  .barPie__ring {
    width: 100%;
    height: 100%;
    position: absolute; 
    top: 0;
    left: 0;
    // -webkit-transform: rotateY(180deg);
    //         transform: rotateY(180deg);
  }
  .barPie__ring label {
    cursor: pointer;
  }
  .barPie__ring__item {
    position: absolute;
    width: 5px;
    height: 50%;
    top: 0;
    left: 50%;
    margin-left: -5px;
    -webkit-transform-origin: 50% 100%;
        -ms-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transition: .1s;
            transition: .1s;
  }
  .barPie__ring__item::before {
    content: '';
    display: block;
    width: 50%;
    height: 30%;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 0 4px rgba(0, 0, 0, 0.33) inset;
    -webkit-transition: .3s;
            transition: .3s;
  }
  /deep/.el-progress-circle{
    width: 50px !important;
    height: 50px !important;
  }
</style>