<template>
  <div class='home'>
    <swiper class="swiper" :options="swiperOption" ref="mySwiper"  v-if="backgroundList.length" >
      <swiper-slide
        v-for="(item,index) in backgroundList"
        :key="index">
                <img  :style=" {cursor:'pointer' }" :src=" item.img" @click="toLink(item.url)"/>
      </swiper-slide>
    </swiper>

    <!-- 头部 -->
    <div class="header-left" @click="!uploading?$router.push('/home'):''" @mouseenter.stop="show=true" @mouseleave="show = false">
      <img :src="webInfo.logo_img" class="logo"/>
      <span>{{webInfo.web_name}}</span>
      <img src="@assets/img/index/icon-drop.png" :class="show?'drop active-drop':'drop'" />
      <el-collapse-transition>
        <div class="other-card"  @mouseleave="show = false" v-show="show" v-clickoutside="handleClose">
          <ul>
            <li v-for="(item,index) in homeInfo.logo_link_list" :key="index">
              <div @click.stop="Jump(item)">{{item.title}}</div>
<!--              <router-link :to="item.url">{{item.title}}</router-link>-->
            </li>
          </ul>
        </div>
      </el-collapse-transition>
    </div>
<!--    -->
    <div v-if="token == ''" class="header-right">
      <div class="handel-router" v-if="isNavShwo">
        <ul>
          <li>
            <router-link  to="/help">
              <span class="font">帮助</span>
            </router-link>
          </li>
          <li>
            <router-link to="" style="border-right: none;">
                <span class="font" @click.stop="codeVerify" >
                 注册/登录
                 <!--   小红点   -->
                 <span class="badge"></span>
               </span>
            </router-link>
          </li>
        </ul>
      </div>

    </div>
    <div v-else class="header-right" style="background: none" v-show="isNavShwo">
      <div class="handel-router">
        <ul>
          <li>
            <router-link to="/shop">升级会员</router-link>
          </li>
          <li>
            <div @click="openPickup">文件传输</div>
          </li>
          <li>
            <router-link to="/myFiles">我的云盘</router-link>
          </li>
          <li>
            <router-link to="/transferRecord">传输记录</router-link>
          </li>
          <li>
            <router-link to="/help">帮助</router-link>
          </li>
        </ul>
      </div>
      <div class="other"  @mouseout="showHome = false" @mousemove="showHome = true"   @click="showHome = !showHome" >
        <span>{{userInfo.username}}</span>
        <img :src="userInfo.avatar" class="avatar"/>
      </div>
    </div>
    <el-collapse-transition>
<!--      showHome-->
      <div class="header-popuBox" v-show="showHome" @mouseout.prevent="showHome = false"  @mousemove.prevent="showHome = true">
        <div class="kc-UserInfoBox">
          <div class="user"  @click="$router.push('/setting')">
            <div class="kc-level">
              <img  :class="userInfo.vip_status == 1?'kc-avatar':'kc-avatar1'" :src="userInfo.avatar" alt="">
              <img v-if="userInfo.vip_status == 1" :src="getShopUserLevelImg(userInfo,time)"  class="kc-vip"  alt="">
            </div>
            <div class="nameBox" >
              <div class="name">
                <span>{{userInfo.username}}</span>
                <img v-if="userInfo.vip_status == 1"  class="vipType" :src="userInfo.vip_level | getVipType" alt="">
              </div>
              <span @click.stop="$router.push('/shop')" :style="{color:userInfo.vip_status == 0 ?'#0080FF':'#333333',fontSize:12 + 'px'}" class="member">{{userInfo.vip_status == 0?'开通点亮VIP': userInfo.vip_level_show +  '至:' + userInfo.vip_expiry_time}}</span>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <ul>
            <router-link to="/setting">
              <li>
                <img src="@assets/img/index/icon-title1.png" alt="">
                账号设置
              </li>
            </router-link>
            <router-link to="/Shop">
              <li>
                <img src="@assets/img/index/icon-title2.png" alt="">
                迅鸟商城
              </li>
            </router-link>
            <router-link to="/MyWallet">
              <li>
                <img src="@assets/img/index/icon-title5.png" alt="">
                我的钱包
              </li>
            </router-link>
            <router-link to="/messageNotice">
              <li>
                <img src="@assets/img/index/icon-title6.png" alt="">
                消息通知
                <span v-show="userInfo.not_read_num >0" class="tips"></span>
              </li>
            </router-link>
            <router-link to="/uploadBackground">
              <li>
                <img src="@assets/img/index/icon-title7.png" alt="">
                传输背景
              </li>
            </router-link>
            <router-link to="/freeExpansion">
              <li>
                <img src="@assets/img/index/icon-title3.png" alt="">
                免费扩容
              </li>
            </router-link>
            <router-link to="/coupon">
              <li>
                <img src="@assets/img/index/icon-title4.png" alt="">
                优惠券
              </li>
            </router-link>
            <div @click="gologin" to="">
              <li>
                <img src="@assets/img/index/icon-title-out.png" alt="">
                登出
              </li>
            </div>

          </ul>
        </div>
      </div>
    </el-collapse-transition>
    <!-- 文件操作  -->
    <div class="card" v-show="card" v-drag>
      <!-- 发文件菜单 -->
      <transition name="el-zoom-in-bottom">
        <div class="send_file-popver" v-show="showSendFile" v-clickoutside="handleClose2">
          <ul>
            <li >

              <img src="@assets/img/index/icon-file.png"/>
              <span>单个/多个文件</span>
              <input @change="uploadFile($event,'file')" multiple   type="file" >
              <!--              文件上传-->
<!--              <el_Upload :type="1"  @upLoadApy="upload_ap">-->
<!--                <template  #name>-->
<!--       -->
<!--                </template>-->
<!--              </el_Upload>-->
            </li>
            <li >
              <img src="@assets/img/index/icon-folder.png"/>
              <span>整个文件夹</span>
              <input @change="uploadFile($event,'files')" type="file" webkitdirectory>
              <!--              文件上传-->
<!--              <el_Upload :type="2" :random="random" @upLoadApy="upload_ap">-->
<!--                <template  #name>-->
<!--   -->
<!--                </template>-->
<!--              </el_Upload>-->
            </li>
            <li @click="getYpyData('',1);yunpanList=[]" >
              <img src="@assets/img/index/icon-cloud.png"/>
              <span>从我的云盘选择</span>
            </li>
          </ul>
          <div class="cone"></div>
        </div>
      </transition>

      <div class="card-top">
        <div class="file-actions">
          <div class="file-action-item" style="margin-bottom:30px" @click.stop="sendoutFile">
            <img src="@assets/img/index/icon-send.png"/>
            <span>发文件</span>
          </div>
          <div class="file-action-item">
            <img src="@assets/img/index/icon-get.png"/>
            <span  @click="showGetFile=true;getFileVal= '';">取文件</span>
            <div class="get-file" v-show="showGetFile">
              <input type="text" class="inputVal" v-model.trim="getFileVal"  placeholder="请输入取件码或下载链接" autocomplete="off"/>
              <div class="btn btn1" @click="hadelCollect">取件</div>
              <div class="btn btn2" @click="showGetFile=false;getFileVal= '';">取消</div>
            </div>
          </div>
        </div>
        <img src="@assets/img/index/icon-upload.png" class="upload" v-show="!showGetFile"/>
      </div>
      <div class="card-bottom"  v-if="showCardSearch1"  @click="showCardSearch1 = false;showCardSearch = false">
        <img src="@assets/img/index/icon-search.gif"/>
        <span>全盘搜索</span>
      </div>
      <div class="card-bottom-search" v-else>
        <img src="@assets/img/index/icon-search.gif"/>
        <div class="search">
            <input type="text" v-model.trim="searchVal" placeholder="请输入您想要搜索的文件" autocomplete="off"/>
            <div class="btn btn1" @click="searchPage = 1;searchList = [];handleSearch()">搜索</div>
            <div class="btn btn2" @click="btnClone">取消</div>
        </div>
      </div>
    </div>

    <!-- 文件传输 -->
    <div class="file-transfer" v-if="transferShow">
      <div class="transfer-header">
        <h4 @click="reviseUploadTitle" >
          <div  class="inputBox" v-if="uploadTitleState">
            <input v-model="uploadTitleVal"  @blur="handelInput" />
          </div>
          <div class="title" v-else>
            {{ uploadTitle?uploadTitle :'文件传输'}}
          </div>
          <span>共{{fileAllSize? formatSize(fileAllSize ) : formatSize(fileSize / 1000) }}</span>
      </h4>
        <div class="transfer-header-right" v-show="yunpanObj.type != 1">
          <img src="@assets/img/index/icon-send.png" @click.stop="handleshowSendFile2"/>
        </div>
        <!-- 发文件菜单 -->
        <transition name="el-fade-in">
<!--          v-clickoutside="handleClose4"-->
          <div class="send_file-popver" v-show="showSendFile2"  >
            <div class="cone"></div>
            <ul>
              <li >
                <img src="@assets/img/index/icon-file.png"/>
                <span>单个/多个文件</span>
                <input @change="uploadFile($event,'file')" multiple   type="file" >
                <!--              文件上传-->
<!--                <el_Upload :type="1"  @upLoadApy="upload_ap">-->
<!--                  <template  #name>-->
<!--                    -->
<!--                  </template>-->
<!--                </el_Upload>-->
              </li>
              <li  >
                <img src="@assets/img/index/icon-folder.png"/>
                <span>整个文件夹</span>
                <input @change="uploadFile($event,'files')" type="file" webkitdirectory>
                <!--              文件上传-->
<!--                <el_Upload :type="2"  :random="random" @upLoadApy="upload_ap">-->
<!--                  <template  #name>-->
<!--               -->
<!--                  </template>-->
<!--                </el_Upload>-->
              </li>


              <li @click="getYpyData('',1);yunpanList = []" v-show="yunpanObj.type != 2">
                <img src="@assets/img/index/icon-cloud.png"/>
                <span>从我的云盘选择</span>
              </li>
            </ul>

          </div>
        </transition>
      </div>
      <div class="file-list">
        <div class="file-item" v-for="(item,index) in fileList1" :key="index">
          <div class="filr-item-left" >
<!--            <img src="@assets/img/index/icon-word.png"/>-->
             <img :src=getImgType(item.suffix)   />
            <!-- <img src="@assets/img/index/icon-img.png"/> -->
            <p >{{item.name}}</p>
          </div>
          <div class="file-item-right">
<!--            <img src="@assets/img/index/icon-cloud2.png" style="margin-right:10px"/>-->
            <img  @click="delfile(index)" src="@assets/img/index/icon-close2.png"/>
          </div>
        </div>
      </div>
      <div class="setting">
        <div class="setting-item" >
          <div class="setting-item-left">
            搜索权限
            <img :src="s1?helpImg1:helpImg0" @mouseenter="s1=true" @mouseleave="s1=false"/>
            <div class="tooltip s1" v-show="s1">
              <div class="cone"></div>
              <p>
                开启后，该文件可被其他用户搜索或下载，若是私密文件，请谨慎使用。
              </p>
            </div>
          </div>
          <div class="setting-item-right">
            <div :class="searchPermission?'is-checked switch':'switch'" @click="handelsearchPermission">
              <span></span>
            </div>
          </div>
        </div>
        <div class="setting-item">
          <div class="setting-item-left">
            文件定价
            <img :src="s2?helpImg1:helpImg0" @mouseenter="s2=true" @mouseleave="s2=false"/>
            <div class="tooltip s2" v-show="s2">
              <div class="cone"></div>
              <p>
                其他用户下载该文件需支付您设置的金额，收益可提现。
              </p>
            </div>
          </div>
          <div class="setting-item-right" style="position: relative">
            <span v-if="showPrice1" @click="handelPrice">{{ price == 0?'免费':price + '元'}}</span>
            <div v-else>
              <input
                  maxlength="9"
                  style="width: 70px;text-align: right"
                  placeholder=""
                  @blur="showPrice1 = true"
                  v-model="price"
                  type="text"
                  @input="limitInput($event,'value')"
              >
              元
            </div>
            <div class="pricingBox" v-show="showPrice" @mouseout="showPrice2 = false" @mousemove="showPrice2 = true" >
              <div class="send_file-popver"  >
<!--                <div class="cone"></div>-->
                <div class="send_file-popver-title">开通VIP会员</div>
                <ul>
                  <li>
                    <img src="@assets/img/index/icon-user.png" alt="">
                    解锁文件定价特权
                  </li>
                  <li>
                    <img src="@assets/img/index/icon-user.png" alt="">
                    专属文件传输背景
                  </li>
                  <li>
                    <img src="@assets/img/index/icon-user.png" alt="">
                    尊享空间、流量、折扣
                  </li>
                </ul>
                <router-link to="/shop">
                <div class="send_file-popver-btn">
                  立即开通
                </div>
                </router-link>
              </div>

            </div>
          </div>
        </div>
        <div class="setting-item" >
          <div class="setting-item-left">
            提供流量
            <img :src="s3?helpImg1:helpImg0" @mouseenter="s3=true" @mouseleave="s3=false"/>
              <span v-show="uploadBefore.visitor_upload == 0 && isProvideFlow">
                {{ fileAllSize ? formatSize(fileAllSize * flow) : formatSize((fileSize * flow) / 1000)  }}
              </span>
            <div class="tooltip s3" v-show="s3">
              <div class="cone"></div>
              <p>
                对方无需登录即可下载该文件，消耗您提供的流量。
              </p>
            </div>
          </div>
          <div class="setting-item-right">
            <!-- <div class="flow" v-if="isProvideFlow">
              <img src="@assets/img/index/icon-reduce.png" @click="reduce"/>
              <span>{{flow}}</span>
              <img src="@assets/img/index/icon-add.png" @click="upReduce"/>
            </div> -->
            <div class="flow" v-if="isProvideFlow">
              <img src="@assets/img/index/icon-reduce.png" @click="reduce"/>
                <input type="text" v-model="flow"  @input="handelProvideFlow" style="width:40px;text-align: right;text-align: center;margin:0 10px;border: 1px solid #ccc;">
              <img src="@assets/img/index/icon-add.png" @click="upReduce"/>
              <span style="margin-left: 10px;">次</span>
            </div>
<!--           -->
            <div :class="isProvideFlow?'is-checked switch':'switch'" @click="handelProvideFlow"
            v-else>
              <span></span>
            </div>
          </div>
        </div>
        <div class="setting-item">
          <div class="setting-item-left">
            允许他人
          </div>
          <div class="setting-item-right" @click="providePermissionVisible=!providePermissionVisible">
            <i>{{providePermission.val}}</i>
            <img src="@assets/img/index/icon-arrow_right.png" class="arrow"
            :style="providePermissionVisible?'transform:rotate(90deg)':''"/>
            <transition name="el-zoom-in-top">
            <div class="dropdown" v-show="providePermissionVisible">
              <div class="cone"></div>
              <ul>
                <li @click="choiceDropdown(1,'下载、转存及预览')" >下载、转存及预览</li>
                <li @click="choiceDropdown(2,'仅下载或转存')"  >仅下载或转存</li>
                <li @click="choiceDropdown(3,'仅预览')" >仅预览</li>
              </ul>
            </div>
            </transition>
          </div>
        </div>
        <div class="setting-item">
          <div class="setting-item-left">
            有效期
          </div>
          <div class="setting-item-right" @click="validPeriodVisible=!validPeriodVisible">
            <i>{{validPeriod.val | validPeriodType}}</i>
            <img src="@assets/img/index/icon-arrow_right.png" class="arrow"
            :style="validPeriodVisible?'transform:rotate(90deg)':''"/>
            <transition name="el-zoom-in-top">
            <div class="dropdown" v-show="validPeriodVisible">
              <div class="cone"></div>
              <ul>
                <li v-for="(item) in uploadBefore.expiry_day_list" :key="item" @click="choiceTimeDropdown(item,item)"  >{{ item | validPeriodType }}</li>
              </ul>
            </div>
            </transition>
          </div>
        </div>
        <div class="setting-item" style="display:block">
          <div class="setting-item-left">
            补充描述
          </div>
          <div class="setting-item-right" style="margin-top:10px">
            <textarea v-model.trim="supplement" placeholder="请输入描述内容，它将展示给收到的用户。" autocomplete="off"></textarea>
          </div>
        </div>
        <div class="agree">
          <img src="@assets/img/index/icon-agreed2.png" v-if="isTransferAgree" @click="isTransferAgree=false"/>
          <span style="cursor: pointer" v-else class="isAgree" @click="isTransferAgree=true"></span>
          <div class="fontBox">
            <span>我已阅读且同意</span>
            <span class="routerBox" v-for="(item,index) in homeInfo.upload_protocol_list" :key="index">
                      <span @click="toAgree(item)" class="router">《{{item.title}}》</span>
                      <span v-if="homeInfo.upload_protocol_list.length -1 !=  index">和</span>
                    </span>
            <span>并对上传的文件的合法性负全部责任。</span>
          </div>
        </div>
        <div @click="submitUpload" class="btn">
          立即发送
        </div>
      </div>
    </div>
    <!--   取件样式 -->
    <div v-if="showTake" class="collectFileBox">
      <div v-if="reportObj.list" class="collectFile-title">
        <img  class="userAvatar" :src="reportObj.share_info.user_avatar" alt="">
        <div class="userInfo">
          <div class="userName">
            <span>{{reportObj.share_info.username}}</span>
            <span style="margin-left: 10px;color: #666666;">分享的文件</span>
          </div>
          <div class="collectFile-fileName" v-if="reportObj.list.length">
            {{reportObj.list.length == 1?reportObj.list[0].name:reportObj.list[0].name + '等'+reportObj.list.length+'个文件'}}
          </div>
          <div class="collectFile-fileName" v-else>
            文件已经被取消分享了！
          </div>
        </div>
      </div>
      <div v-else class="collectFile-title">
        <div class="cancel-box">
          <div style="width: 100%" class="imgbox"><img class="userAvatar" src="@assets/img/logo.png" alt=""></div>
          <div class="userInfo" style="width: 100%">
            <span>文件已经被取消分享了！</span>
          </div>
        </div>

        </div>
      <div class="collectFile-fileTime" v-if="reportObj.share_info && showdownLoadFile">
        {{reportObj.share_info.expiry_time ? reportObj.share_info.expiry_time + '后到期':'永久有效'}}
        <span @click="showReport = !showReport">举报</span>
      </div>
      <!--  正常上传  -->
      <div v-if="showdownLoadFile" >
        <div class="collectFile-fileText" v-if="reportObj.share_info.remark">
          {{reportObj.share_info.remark}}
        </div>
        <div class="collectFile-downloadBox">
          <div class="collectFile-download">
            <img src="@assets/img/index/icon-download.png" alt="">
            <span>下载需流量：{{formatSize(reportObj.share_info.size_total )}}</span>
          </div>
          <div class="collectFile-download" v-if="reportObj">
            <img src="@assets/img/index/icon-dycdps.png" alt="">
            <span>您剩余流量：{{ reportObj.member_flow_over ?formatSize(reportObj.member_flow_over) :'-' }}</span>
          </div>
        </div>
        <div class="collectFile-fileInfo" @click="showdownload = !showdownload;showSearchResult = false;showLogin = false;isNavShwo = true">
          <div class="details">
            <img :src="getImgType(reportObj.list[0].type == 2?reportObj.list[0].suffix:'folder')" alt="">
            <div class="font">
              <span v-if="reportObj.list.length" style="" class="name">
                {{reportObj.list.length == 1?reportObj.list[0].name:reportObj.list[0].name + '等'+reportObj.list.length+'个文件'}}
              </span>
              <span  v-if="reportObj" class="size">
                {{formatSize(reportObj.share_info.size_total) }}
              </span>
            </div>
          </div>


          <img class="icon" src="@assets/img/index/icon-drop.png" alt="">
        </div>
      </div>
      <!--  取消文件  -->
       <div v-else class="collectFile-cancelBox">
          <img src="@assets/img/index/icon-cancel-icon.png" alt="">
          <div class="collectFile-cancel-font">
            <div class="info">
              <img src="@assets/img/index/icon-download-cancel.png" alt="">
              传输你的大文件
            </div>
            <div class="info">
              <img src="" alt="">
              上传下载
              <p style="color: #1C74C4">不限速</p>
            </div>

          </div>
        </div>
      <!--  按钮样式-->
      <div class="collectFile-fileBtnBox">
        <div v-if="b1 && reportObj.share_info.look_type != 3" class="btnBox">
          <div @click="handelDownLoadFile" class="btn1"> {{checkDownloadLength?'下载已选':'下载'}}</div>
          <div @click="handelRedeposit"  class="btn2">{{checkDownloadLength?'转存已选':'转存'}}</div>
        </div>
        <div v-if="b2" class="btn3" @click="handelPayfile">
          支付￥{{this.reportObj.share_info.price}}
          <img :src="s3?helpImg1:helpImg0" @mouseenter="s3=true" @mouseleave="s3=false"/>
          <div class="tooltip s3" v-show="s3">
            <div class="cone"></div>
            <p>
              下载或转存该文件需要付费，费用由文件发送者收取。
            </p>
          </div>
        </div>
        <div v-if="b3" class="btn4" @click="card = true;showTake = false">
          开始上传
        </div>
      </div>
    <!--     举报弹窗 -->
    <div class="reportBox" v-show="showReport">
        <div class="title">举报</div>
        <div class="reasonBox">
          <span>请选择举报原因：</span>
          <div class="reason">
            <div class="info" @click="item.states = !item.states" v-for="item in reportTags" :key="item.id">
              <img :src="item.states?reason1:reason2" alt="">
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="btnBox">
          <div class="btn1" @click="submitReports" >提交</div>
          <div class="btn2" @click="showReport = false"> 取消</div>
        </div>
      </div>
    </div>
    <!--    取件支付样式  -->
    <div class="collectFilePayBox" v-if="showReportPay">
      <div class="title">
        <span>文件购买</span>
        <img @click="closeshowReportPay" src="@assets/img/index/icon-close6.png" alt="">
      </div>
      <div class="fileBox">
        <div class="top">
          <span class="name">
            文件名称：
            <p> {{reportObj.list.length == 1?reportObj.list[0].name:reportObj.list[0].name + '等'+reportObj.list.length+'个文件'}}</p>
          </span>
          <span>
            文件费用：
            <p style="color: #FF575B">{{reportObj.share_info.price}}元</p>
          </span>
          <span>
            文件上传者：
            <p>{{reportObj.share_info.username}}</p>
          </span>
        </div>

      </div>
      <div class="payBox">
        <div class="info">
          <div class="payType">
            <div class="pay-title">选择支付方式</div>
            <div class="btnBox">
              <div :class="payIndex == 1?' btn btn1':'btn btn1_a'" @click="payIndex = 1">
                微信支付
                <img v-show="payIndex == 1" src="@assets/img/index/icon-pay-icon.png" alt="">
              </div>
              <div :class="payIndex == 2?'btn btn2':'btn btn2_a'" @click="payIndex = 2">
                支付宝支付
                <img v-show="payIndex == 2" src="@assets/img/index/icon-pay-icon.png" alt="">
              </div>
            </div>
          </div>
          <div class="payQr">
            <img :src=payObj.pay_img alt="">
          </div>
          <div class="payFont">
            <ul>
<!--              <li>支付金额：</li>-->
              <li>
                {{payIndex == 1?'微信':'支付宝'}}
                <span class="money">￥{{reportObj.share_info.price}}</span>
  <!--              <span class="discount">以优惠15元</span>-->
              </li>

              <li>使用{{payIndex == 1?'微信':'支付宝'}}扫描二维码</li>
            </ul>
          </div>
        </div>
        <div class="bottom">
          <span>支付说明：该费用由文件发送者收取，请你自行判断文件的真实性，虚拟物品一经交易不支持退款</span>
        </div>
      </div>
<!--      <div class="btn" @click="showReportPay = false">-->
<!--        取消-->
<!--      </div>-->
    </div>
    <!--   下载或预览消息弹窗样式-->
    <div class="downloadPupBox" v-if="showdownLoadTips">
      <img class="del"  @click="showdownLoadTips = false" src="@assets/img/index/icon-close2.png" alt="">
      <div class="title">流量消耗提示</div>
<!--      下载-->
      <div class="fontBox" v-if="handelIndex == 1">
        <div class="font" v-if="reportObj.share_info.free_flow_total >= downLoadFileSize ">
          <span>文件{{formatSize(downLoadFileSize)}}，在线下载需{{ formatSize(downLoadFileSize) }}流量，无须消耗您的流量!</span>
        </div>
        <div class="font" v-else>
          <span>文件{{formatSize(downLoadFileSize )}}；</span>
          <span>在线下载需{{ formatSize(downLoadFileSize) }}流量</span>
          <span>(您还剩{{formatSize(reportObj.member_flow_over )}})</span>
          <span v-show="!f3">，是否确认下载？</span>
        </div>
      </div>
<!--      预览-->
      <div class="fontBox" v-else>
        <div class="font">
          <div class="font" >
            <span>文件{{ formatSize(previewFileObj.size) }}；</span>
            <span>在线预览需{{formatSize(previewFileObj.size)}}流量</span>
            <span>(您还剩{{formatSize(reportObj.member_flow_over)}})</span>
            <span v-show="!f3">，是否确认预览?</span>
          </div>
        </div>
      </div>
<!--      下载-->
      <div v-if="f1" class="btnBox">
        <div class="btn1" @click="handelFile">确认下载</div>
        <div class="btn2" @click="showdownLoadTips = false;continueDownLoadTipsState = true">取消</div>
      </div>
      <!--      预览-->
      <div v-if="f2" class="btnBox">
        <div class="btn1" @click="handelFile">确认预览</div>
        <div class="btn2" @click="showdownLoadTips = false;continueDownLoadTipsState = true">取消</div>
      </div>
      <div v-if="f3" class="btnBox" @click="toShop" >
        <div class="btn3">
          当前账户流量余额不足，前往充值
          <img src="@assets/img/index/icons-next.png" alt="">
        </div>
      </div>

    </div>
    <!--   是否继续打包提示-->
    <div class="downloadPupBox" v-if="showContinueDownLoadTips">
      <img class="del"  @click="showContinueDownLoadTips = false" src="@assets/img/index/icon-close2.png" alt="">
      <div class="title">继续打包提示</div>
      <div class="fontBox">
        <div class="font">
          <span>打包这批文件大约需要{{packZipTime}}分钟，建议选择单个文件下载!</span>
        </div>
      </div>
      <div  class="btnBox">
        <div class="btn1" @click="handelDownLoadFile();continueDownLoadTipsState = false;showContinueDownLoadTips = false ">继续打包</div>
        <div class="btn2" @click="showContinueDownLoadTips = false;continueDownLoadTipsState = true">取消</div>
      </div>


    </div>
    <!--   下载左边弹窗显示-->
    <div :class="showdownload?'downloadBox show' :'downloadBox'" >
      <div class="download-title">
        <img src="@assets/img/index/icon-close2.png"  @click="showdownload = false" alt="">
      </div>
      <div class="download-content" >
        <div class="title">
          <div class="path">
            <span @click="handelDownloadName(item,1,index)"  v-for="(item,index) in downLoadTitle" :key="index">
            <div style="max-width: 80px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden">{{item.title}}</div>
            <div v-show="downLoadTitle.length - 1 != index" class="down"> > </div>
          </span>
          </div>
          <div class="selectAll" @click="handelDownloadClickAll">
            <img  :src = "isDownloadCheckAll? reason1 : reason2 "  alt="">
            全选
          </div>
        </div>

        <div class="content" >
          <div class="infoBox" v-for="(item,index) in folderList" :key="index">
            <div class="info"    >
<!--              <img @click="handelDownloadData(item);item.state = !item.state" :src = "item.type == 2 ?item.state? reason1 : reason2 :wenjianjia"  alt="">-->
              <img @click="handelDownloadData(item);item.state = !item.state" :src = "item.state? reason1 : reason2 "  alt="">
              <img class="icon" :src="getImgType(item.suffix)" alt="">
              <span @click="handelDownloadName(item,item.type)">{{ item.name }}</span>
            </div>
            <div class="size">
              {{item.size == 0 ? '-':formatSize(item.size)}}
            </div>
            <div class="preview" v-if="item.type == 2"  @click="handelpreviewFile(item)">
              <img :src="openImg" alt="">
              <span>预览</span>
            </div>
            <div v-else class="preview"></div>
          </div>
        </div>
        <div class="total">
          共{{folderList.length}}项
        </div>
      </div>
    </div>
    <!-- 打包/解压任务 -->
    <div class="upload-dialog" v-if="showZip">
      <div class="upload-dialog__header">
        <div class="upload-dialog__header-title">打包/解压任务</div>
        <div class="upload-dialog__header-right">
          <img src="@assets/img/file/icon-drop.png" />
          <img  @click="showZip = false" src="@assets/img/file/icon-close.png" style="margin-left:20px;"/>
        </div>
      </div>
      <div class="zip-list">
        <div class="zip-item" v-for="(item,index) in packZipList" :key="index">
          <div class="zip-item-content">
            <img src="@assets/img/profile/icon-zip1.png"/>
            <div class="zip-item-info">
              <h6>{{item.file_name}}</h6>
              <p>{{item.states == 0 ? '打包中，大约需要' +packZipTime+ '分钟，完成后自动下载':'打包完成'}}</p>
            </div>
          </div>
          <div class="zip-operate" v-show="item.states == 1">
            <!-- <span>取消任务</span> -->
            <img src="@assets/img/profile/icon-success.png" style="margin-right:20px;"/>
            <img @click="window.open(`${item.url}`,'_blank')" src="@assets/img/profile/icon-download.png" style="cursor: pointer;"/>
          </div>
        </div>
      </div>
    </div>
    <!-- 下载之后弹窗信息  -->
    <div class="downloadAfterBox" v-show="downloadAfter">
      <div class="downloadAfter-titleLogo">
        <img src="@assets/img/index/icon-downloadAfterTitleImg.gif" alt="">
      </div>
      <span class="title"><p>下载已开始</p></span>
      <div class="downloadAfter-details">
        <div v-html="downLoadMsg"></div>
      </div>
      <div class="downloadAfter-info">
        <ul>
          <li>
            <img src="@assets/img/index/icon-downloadAfterInfoImg.png" alt="">
            <span>在浏览器的下载任务管理器中可查看下载进度</span>
          </li>
          <li>
            <img src="@assets/img/index/icon-downloadAfterInfoImg.png" alt="">
            <span>下载的文将保存到浏览器设置的下载路径中</span>
          </li>
          <li>
            <img src="@assets/img/index/icon-downloadAfterInfoImg.png" alt="">
            <span>若下载被浏览器拦截请手动设置权限恢复</span>
          </li>
        </ul>
      </div>
      <div class="downloadAfter-footer">
        <img @click="gobackPickUp" src="@assets/img/index/icon-downloadAftergoback.png" alt="">
        <div class="btn" @click="openPickup">
          文件传输
        </div>
      </div>
    </div>
    <!-- 打包/解压任务窗口缩小样式 -->
    <div class="circle-progress" v-if="showZipType">
      <Circles :percent="80" :size="90" :stroke-width="10" :trail-width="10"  trail-color="#cce6ff">
        <span class="demo-Circle-inner" style="font-size:24px">80%</span>
      </Circles>
    </div>
    <!-- 我的云盘 -->
    <div class="cloudDisk" v-if="cloudDiskVisible">
      <img src="@assets/img/index/icon-close.png" class="close" @click="cloudDiskVisible=false"/>
      <div class="cloudDisk-header">
        <h5>我的云盘</h5>
        <div class="title">
          <h6 v-for="(item,index) in yunpanTitle" :key="index" @click="yunpanIndex = 1;getYpyData(item,1,index)">
            <img src="@assets/img/index/icon-files.png"/>
            <span>{{item.title}}</span>
            <div style="margin: 0 5px" v-show="yunpanTitle.length - 1 != index"> > </div>
          </h6>
        </div>
      </div>
      <div class="files">
        <!-- 全选 -->
        <div class="all-file">
          <div class="file-left">
            <span class="checkbox_input" @click="handleClickAll();yunpanAllCheck = !yunpanAllCheck">
              <img :src="yunpanAllCheck?checkboxImg1:checkboxImg0"/>
<!--              <input type="checkbox" :checked="yunpanAllCheck" aria-hidden="false"/>-->
            </span>
            <i>名称</i>
          </div>
          <div class="file-right">大小</div>
        </div>
        <!-- 文件列表 -->
        <div class="file-list" @scroll="getYunpanPage">
          <div class="file-item" v-for="(item,index) in fileList" :key="index" >
            <div class="file-item-left">
              <span class="checkbox_input" :style="item.isCheck?'visibility:visible':''">
<!--              切换数据时选中保留数据  <img @click="pushIsCheck(item);item.isCheck=!item.isCheck;"  :src="item.isCheck?checkboxImg1:checkboxImg0"/>-->
                <img @click="item.isCheck=!item.isCheck;"  :src="item.isCheck?checkboxImg1:checkboxImg0"/>
<!--                  <input type="checkbox" :checked="item.isCheck" aria-hidden="false"/>-->
              </span>
              <img  src="@assets/img/index/icon-file.png" class="icon-file"/>
              <p @click="yunpanIndex = 1;getYpyData(item,item.type,index);" >{{ item.name }}</p>
            </div>
            <div class="file-item-right">
              {{item.size?formatSize(item.size):'-'}}
            </div>
          </div>
        </div>
      </div>
      <div class="tools">
        <p>已选{{checkLength}}项，{{formatSize(checkSize)}}</p>
        <div  class="cancel">
          <span @click="cloudDiskVisible = false">取消</span>
        </div>
        <div class="confirm" @click="submityunpanData">确定</div>
      </div>
    </div>
    <!-- 传输/分享 -->
    <div class="transfer_share"  v-if="transferShareVisible">

      <div class="transfer">
        <div v-if="percentage == 100" class="transfer_titleImgBox" >
          <img src="../assets/img/index/upload_ok.png" alt="">
        </div>
        <CircleLoading v-else  :percentage="percentage"/>
        <p>{{this.speed}}</p>
        <router-link to="">
<!--          传输预览<img src="@assets/img/index/icon-arrow_right.png"/>-->
        </router-link>
      </div>
      <div class="content">
        <h5>链接或取件码分享</h5>
        <div class="link">
          <div class="link-item">
            <span>链接</span>：
            <p
                v-if="copy1"
                @mouseout="copy1 = false"
                class="copy1"
                v-clipboard:copy="link"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
            >复制链接</p>
            <p v-else @mousemove="copy1 = true" >{{link?link:'上传完成后生成...'}}</p>

          </div>
          <div class="link-item">
            <span>取件码</span>：
            <p
               v-if="copy2"
               @mouseout="copy2 = false"
               class="copy1"
               v-clipboard:copy="link_code"
               v-clipboard:success="onCopy"
               v-clipboard:error="onError"
            >复制取件码</p>
            <p v-else @mousemove="copy2 = true" >{{link_code?link_code:'上传完成后生成...'}}</p>
          </div>
        </div>
        <h6>
          <i></i>
          <span>其他方式</span>
          <i></i>
        </h6>
        <div class="type">
          <img src="@assets/img/index/icon-qr.png" @click="link?shareQrVisible=true:shareQrVisible=false"/>
          <img src="@assets/img/index/icon-email.png" @click="link?shareEmailVisible=true:shareEmailVisible=false"/>
        </div>
        <!--             link+-->
        <div class="copy"
        @click="handelCopy"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        >一键复制</div>
        <div class="again" v-if="percentage == 100">
          <span @click="againUpload">再传一次</span>
        </div>
      </div>
      <!-- 扫码分享 -->
      <div class="share-qr" v-show="shareQrVisible">
        <img src="@assets/img/index/icon-close3.png" class="close" @click="shareQrVisible = false"/>
        <h4>扫码分享</h4>
        <img :src="share_qr" class="qr"/>
<!--        <a :href="posterUrl" download="share.png"   class="save">保存图片</a>-->
        <div class="save" @click="downloadImg">保存图片</div>
      </div>
      <div class="share-email" v-show="shareEmailVisible">
        <img src="@assets/img/index/icon-close3.png" class="close" @click="shareEmailVisible = false"/>
        <h4>发送至邮箱</h4>
        <div class="send-email" v-for="(item,index) in sendEmail" :key="index">
          <input type="email" v-model.trim="item.email" placeholder="请输入邮箱" autocomplete="off"/>
          <img src="@assets/img/index/icon-clear.png" class="clear" v-show="item.email.length!=0" @click="item.email=''"/>
          <img src="@assets/img/index/icon-email_add.png" class="add" v-if="(index+1)==sendEmail.length" @click="sendEmail.push({email:''})"/>
        </div>
        <div class="send" @click="handelEmail">发送</div>
      </div>
    </div>
    <!-- 搜索结果 -->
    <div v-if="showSearchResult" :class="showSearchResult?'search-result show':'search-result'">
      <div class="search-result-header">
<!--        <img src="@assets/img/index/icon-close4.png" @click="showSearchResult=false"/>-->
      </div>
      <div class="searchBox">
          <div class="search">
            <input type="text" v-model.trim="searchVal" placeholder="请输入您想要搜索的文件" autocomplete="off"/>
            <div class="btn" style="background: linear-gradient(143deg, #22deb5 0%, #06a7f0 100%);" @click="searchPage = 1;searchList = [];handleSearch()">搜索</div>
            <div class="btn" style="background: #ffffff;color:#06a7f0;" @click="showSearchResult=false;isNavShwo = true">关闭</div>
          </div>
        </div>
      <div class="search-result-content">
        <div class="file-itemBox">
          <div class="scroll-box" v-if="searchList.length !=0">
          <div class="file-item" v-for="(item,index) in searchList" :key="index">
          <div class="file-item-header">
            <div class="file-item-header_left">
              <img src="@assets/img/index/icon-send_file.png"/>
              <span style="margin-right:5px;">{{item.username}}</span>
              <span>分享的文件 {{ formatSize(item.size_total) }}</span>
            </div>
            <div class="file-item-header_right">
              <img src="@assets/img/index/icon-time.png"/>
              <span>过期时间：{{ item.expiry_day == 0?  '永久有效' :item.expiry_time + '后到期'  }}</span>
            </div>
          </div>
          <div class="file-item-content"  >
            <div class="file-item-content_left">
              <div class="file-name">
                {{ item.title }}  {{ formatSize(item.size_total) }}
              </div>
              <div class="file-price" v-show="item.need_pay == 1">
                <img src="@assets/img/index/icon-money.png"/>￥{{ item.price }}
              </div>
            </div>
            <div class="btn" @click="checkFileInfo(item)">查看详情</div>
          </div>
        </div>
        </div>
          <div class="file-font"  v-else >
            查无此件，还没有用户分享您搜索的文件呢!
          </div>
          <el-pagination
            hide-on-single-page 
            @current-change="handleCurrentChange"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="5"
            layout="prev, pager, next"
            :total="this.searchCount">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 登录/注册/忘记密码 -->
    <div v-if="showLogin"  :class="showLogin?'box show':'box'">
      <img class="close5" src="@assets/img/index/icon-close5.png" @click="showLogin = false" alt="">
      <!-- 登录注册 -->
      <div class="content" v-if="!showForgetPwd">
        <transition name="el-fade-in">
          <div class="content-loginBox" v-show="showQr">
            <div class="login-wxQrBox" @click="switchLogin(1)">
<!--              <span >微信登录/注册</span>-->
              <img class="title" src="@assets/img/index/icon-pwdlogin.png" alt="">
              <img src="@assets/img/index/qr-login.png" alt="">
            </div>
            <div class="loginBox">
              <div class="login-type">
                <a href="javascript:;" @click="loginType=1">短信登录/注册</a>
                <a href="javascript:;" @click="loginType=2">密码登录</a>
                <div :class=" loginType == 1?  'active-bar code':'active-bar pwd'" ></div>
              </div>
              <div class="login-form">
                <div class="form-item">
                  <span>+86</span>
                  <input type="tel" maxlength="11" v-model="phone" placeholder="手机号" autocomplete="off"/>
                </div>
                <div class="form-item" v-if="loginType==1">
                  <input type="text" maxlength="4" v-model="code" placeholder="验证码" autocomplete="off" style="width:212px;padding-left:20px"/>
                  <i @click="getVerificationCode(1)" >{{codeText}}</i>
                </div>
                <div class="form-item" v-else>
                  <input type="password" v-model="password" placeholder="密码" autocomplete="off" style="padding-left:20px"/>
                </div>
                <div class="login-btn"  @click="submit">注册/登录</div>
                <div class="agree" v-if="loginType==1">
                  <img src="@assets/img/index/icon-agreed.png" v-if="isAgree" @click="isAgree=false"/>
                  <span class="isAgree" v-else @click="isAgree=true"></span>
                  <div class="fontBox">
                   <span>我已阅读且同意</span>
                    <span class="routerBox" v-for="(item,index) in homeInfo.reg_protocol_list" :key="index">
                      <span @click="toAgree(item)" class="router">《{{item.title}}》</span>
                      <span v-if="homeInfo.reg_protocol_list.length -1 !=  index">和</span>
                    </span>
<!--                    <span>，并对上传的文件的合法性负全部责任。</span>-->
                  </div>
                </div>
                <div class="forget" v-else>
                  <a href="javascript:;" @click="showForgetPwd=true">忘记密码</a>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="el-fade-in">
          <div class="login-qrBox" v-show="showQr1">
            <div class="login-qr-navBar" @click="switchLogin(2)">
              <img class="wx1" src="@assets/img/index/icon-wxlogin-tips.png" alt="">
              <img class="wx2" src="@assets/img/index/icon-wxlogin.png" alt="">
            </div>
            <div class="qr-title">
              微信登录
            </div>
            <p class="qr-tip">扫码后点击“关注”即可登录</p>
            <div class="login-qr">
              <div class="qr-content">
                <img :src="QRImgUrl" class="qr"/>
                <div class="err" v-if="qrErr" @click="getWechatLoginQrCode()">
                  <img src="@assets/img/index/icon-refresh.png"/>
                  <span>二维码已失效</span>
                  <span>点击刷新</span>
                </div>
              </div>
              <p>
                <img src="@assets/img/index/icon-wx.png"/>
                <span>微信扫码</span>
              </p>
            </div>

          </div>
        </transition>
        <transition name="el-fade-in">
        <div class="login-bottom-fontBox" v-show="showfont">
          <div class="title">登录畅享更多特权</div>
          <ul>
            <li>
              <img src="@assets/img/index/icon-login-fontIcon.png" alt="">
              注册就送空间和流量
            </li>
            <li>
              <img src="@assets/img/index/icon-login-fontIcon.png" alt="">
              上传下载永不限速
            </li>
            <li>
              <img src="@assets/img/index/icon-login-fontIcon.png" alt="">
              解锁收发文件特权
            </li>
            <li>
              <img src="@assets/img/index/icon-login-fontIcon.png" alt="">
              下载免客户端
            </li>
          </ul>
        </div>
        </transition>
      </div>
      <!-- 忘记密码 -->
      <div  class="content" style="height: auto" v-else>
        <div class="content-loginBox">
          <div class="loginBox">
            <div class="login-type">
              <a href="javascript:;">忘记密码</a>
              <div class="active-bar" style="left:50%;transform:translateX(-50%)"></div>
            </div>
            <div class="login-form" v-if="!ispwdEditSuccess">
              <div class="form-item">
                <span>+86</span>
                <input type="tel" maxlength="11" v-model="fphone" placeholder="手机号" autocomplete="off"/>
              </div>
              <div class="form-item">
                <input type="text" maxlength="4" v-model="fcode" placeholder="验证码" autocomplete="off" style="width:212px;padding-left:20px"/>
                <i @click="getVerificationCode(2)" >{{fcodeText}}</i>
              </div>
              <div class="form-item">
                <img :src="newPwdShow?openImg:closeImg" @click="newPwdShow=!newPwdShow"/>
                <input :type="newPwdShow?'text':'password'" v-model="fpassword" placeholder="新密码" autocomplete="off" style="padding-left:20px"/>
              </div>
              <div class="form-item">
                <img :src="newPwdShow2?openImg:closeImg" @click="newPwdShow2=!newPwdShow2"/>
                <input :type="newPwdShow2?'text':'password'" v-model="fpassword2" placeholder="确认新密码" autocomplete="off" style="padding-left:20px"/>
              </div>
              <div class="login-btn" @click="submitFindPwd">提交</div>
              <div class="return-login" @click="showForgetPwd=false">
                <span>返回登录</span>
              </div>
            </div>
            <div class="forget_pwd-success" v-else>
              <img src="@assets/img/index/icon-edit_success.png"/>
              <span>密码修改成功</span>
              <div class="btn" @click="showForgetPwd=false">返回登录</div>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- 添加评论 -->
    <div class="add-comment" v-if="showTake">
<!--      <div class="add-comment-content" v-if="!showTake">-->
<!--        <img src="@assets/img/index/icon-edit.png"/>-->
<!--        <span>我来说两句</span>-->
<!--      </div>-->
      <div class="add-comment-form" >
        <div :class="commentForm?'is-checked switch':'switch'" @click="handelsearchCommentForm">
          <span :style="{color:commentForm?'#08ACEC':'#666666'}">弹</span>
        </div>
        <img src="@assets/img/index/icon-edit.png"/>
        <input type="text" v-model.trim="comment" placeholder="发表神妙评论" autocomplete="off"/>
        <div class="btn" @click="submitComment">发送</div>
      </div>
    </div>
    <!-- 评论弹幕 -->
    <div class="baberrageBox" v-if="showCommentForm">
      <vue-danmaku ref="danmaku"  class="danmaku" v-model="danmus" :useSlot="true" :speeds="100" :isSuspend="true" :randomChannel="true" :loop="true">
        <template  slot="dm" slot-scope="{ index, danmu }">
          <div class="baberrage-infoBox" @click="clickPraise(danmu)">
            <img :src="danmu.avatar" alt="">
            <span class="name">{{danmu.name}} ： </span>
            <span class="msg">{{ danmu.text }}</span>
            <div class="dianzan">
              <img :src= danmu.state?dianzan1:dianzan alt="">
              <span class="dianzan1" :style="{Color:danmu.state==true?'#EC675B':'#333333'}">{{danmu.likes_num}}</span>
            </div>
          </div>

        </template>
      </vue-danmaku>
    </div>
    <!-- 移动到-->
    <handelPopup @handelPopupFile="handelPopupFile" @closepopup="popup = false" :title="popuptitle" v-show="popup"/>
    <!-- 预览文件 -->
    <previewFileComponents
        v-show="showPreviewFile"
        :showPreviewFile="showPreviewFile"
        :fileInfo="previewFileObj"
        :fileUrl="fileUrl"
        :fileType="fileType"
        @close="showPreviewFile = false"
    />
    <!--    下载弹窗  -->
    <overallPopup @closePopup="closePopup"/>
    <div class="alink">
      <span  :style="{'--border': footerLinkList.length -1 != index ? '1px solid #fff' :'none'}" v-for="(item,index) in footerLinkList" :key="index" @click="Jump(item)">
      {{item.title}}
      </span>
    </div>
  </div>
</template>


<script>
import crypto from "crypto";
const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  // update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
const drag={
  bind(el){
  // 监听 当前绑定指令元素的鼠标点下去的事件
    el.onmousedown = function (e) {
      // console.log(e);
      const {
        ox,
        oy
      } = {
        ox: e.clientX - el.offsetLeft,
        oy: e.clientY - el.offsetTop
      }
      // 监听鼠标来回移动的事件
      document.onmousemove = function (em) {
        const {
          left,
          top
        } = {
          left: em.clientX - ox,
          top: em.clientY - oy
        }
        // 将鼠标 换成小手
        el.style.cursor = 'pointer'
        // 和盒子的定位 改变left 和 top的值
        el.style.left = left + 'px'
        el.style.top = top + 'px'
      }
      // 监听鼠标抬起的事件
      document.onmouseup = function (eu) {
        document.onmousemove = null
        el.style.cursor = 'default'
      }
    }
  }
};
import CircleLoading from '@components/CircleLoading'
//页面引入swiper
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import {
  clickComment,
  createShareQr,
  fileDownload,
  getCode,
  getFileFolder,
  getFileInfo,
  getHomeInfo,
  getPanList,
  getReportTags,
  getWebInfo,
  getWxCode,
  inquireTask,
  phoneLogin,
  previewFile,
  search,
  sendComment,
  sendOutEmail,
  SubmitReport,
  uploadBefore,
  uploadFile,
  uploadFileByPan,
  WxCodeLogin,
  findPwd,
  pwdLogin,
  saveToDisk,
  sendBuyShare,
  checkLinkOrderPay,
  getSizeTotal,
  downloadQrImage
} from "@assets/api/home/home";

import axios from "axios";
import {_throttle, editE, filterKeyword, formatSize, getSign} from "@utils/function";
import {baseUrl, icon_type} from "../../config";
//评论弹幕
import vueDanmaku from 'vue-danmaku'
import {mapState} from "vuex";
import handelPopup from "@components/handelPopup";
import previewFileComponents from '@components/previewFile'
import {getUserInfo} from "@assets/api/user/user";
import {getNewsList} from "@assets/api/advice";
import kcHeader from '@components/kcHeader'
import overallPopup from '@components/overallPopup'
//html2Canvas

export default {
  name: "",
  data() {
    return {
      homeBg:require('@assets/img/index/bg.png'),
      reg:/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      openImg:require('@assets/img/index/icon-open.png'), //密码明文
      closeImg:require('@assets/img/index/icon-off.png'), //密码密文
      checkboxImg0:require('@assets/img/index/icon-select0.png'),
      checkboxImg1:require('@assets/img/index/icon-select1.png'),
      helpImg0:require('@assets/img/index/icon-transfer_help0.png'),  //文件传输帮助图标
      helpImg1:require('@assets/img/index/icon-transfer_help1.png'),  //文件传输帮助图标
      reason1: require('@assets/img/index/icon-reason1.png') ,//举报勾选
      reason2:require('@assets/img/index/icon-reason2.png'),//举报未勾选
      wenjianjia:require('@assets/img/index/icon-wenjianjia.png'),//举报未勾选
      dianzan:require('@assets/img/index/dianzan.png'),//未点赞
      dianzan1:require('@assets/img/index/dianzan1.png'),//点赞
      swiperOption: {
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        observeParents:true,
      },
      //转存
      popup:false,
      popuptitle:'',
      //
      userInfo:{},//用户信息
      window:window,
      show:false, //logo旁下拉菜单显示
      card:true,//第一个发文件可移动窗口
      isNavShwo:true, // 导航是否隐藏
      showHome:false,//首页登录状态 头像弹窗
      showSendFile:false, //发文件菜单显示 --文件操作
      showSendFile2:false, //发文件菜单显示 --文件传输
      showLogin:false, //登录菜单显示
      showdownload:false,//下载菜单显示
      showGetFile:false, //取文件显示
      showCardSearch:false, //搜索显示
      showCardSearch1:true,//搜索显示
      showSearchResult:false, //搜索结果显示
      showPrice:false,//会员定价弹窗
      showPrice1:true,//会员定价 输入框 和免费切换
      showPrice2:true,//会员定价弹窗 自动消失
      showForgetPwd:false, //登录 忘记密码 切换
      showQr:false,//微信扫码登录
      showQr1:true,//账号密码登录
      showfont:true,//底部文案
      getFileVal:'',//取件码/链接
      price:'',//定价输入框
      downLoadMsg: '',
      downLoadType: null,
      //取件

      downloadAfter:false,//下载之后弹窗
      flowType:false,//消耗随的流量 true 自己流量 false 发件者流量

      showTake:false,//取件弹窗
      showReport:false,//取件弹窗举报
      showdownLoadTips:false,//消耗流量窗口提示
      showContinueDownLoadTips:false,//是否继续打包提示
      showdownLoadFile:false,//取件界面
      payIndex:1,//取件支付按钮下标
      showReportPay:false,//支付弹窗
      reportObj:{},//取件对象
      folderList:[],//取件文件数组
      downLoadList:[],//取件文件下载或转存数组
      downLoadTitle:[{id:'', title:'传输详情'},],//下载头部名称
      previewFileObj:{},//预览文件对象
      btn1:false,//取件按钮文案状态
      b1:false,//取件按钮状态
      b2:false,//取件按钮状态
      b3:false,//取件按钮状态
      handelIndex:0,//操作状态 0 预览 1 下载
      downLoadFileSize:0,//选中下载文件的大小
      payObj:{},//取件支付数据
      f1:false,//下载或浏览弹窗文案和按钮样式
      f2:false,//下载或浏览弹窗文案和按钮样式
      f3:false,//下载或浏览弹窗文案和按钮样式
      //打包解压窗口
      showZip:false,//打包解压窗口
      showZipType:false,//打包解压缩小窗口
      packZipList:[],//打包下载数组
      continueDownLoadTipsState:true,//继续打包
      isFirstClick:true,
      //登录/注册
      QRImgUrl:'', //微信二维码
      qrErr:false, //微信二维码是否失效
      loginType:'1', //登录方式 1短信 2密码
      phone:'',
      code:'',
      password:'',
      isAgree:false,
      codeText:'发送验证码',
      leader_id:'',//推荐人id
      //忘记密码
      fphone:'',
      fcode:'',
      fpassword:'',
      fpassword2:'',
      fcodeText:'发送验证码',
      newPwdShow:false, //新密码是否明文
      newPwdShow2:false, //确认新密码是否明文
      ispwdEditSuccess:false, //密码是否修改成功

      transferShow:false, //文件传输弹窗显示

      s1:false, //搜索权限提示
      s2:false, //定价提示
      s3:false, //提供流量提示
      redepositState:true,//转存状态
      searchPermission:false,//搜索权限
      isProvideFlow:false, //是否自定义提供流量
      providePermissionVisible:false, //允许他人弹窗
      validPeriodVisible:false, //有效期弹窗

      flow:'1',//提供的流量
      providePermission:{id:1,val:'下载、转存及预览'}, //允许他人
      validPeriod:{},//有效期
      supplement:'', //补充描述
      isTransferAgree:false, //文件传输 协议

      cloudDiskVisible:false,//我的云盘显示
      fileList:[],//我的云盘数组
      yunpanList:[],//从我的云盘上传文件
      yunpanTitle:[],//云盘头部
      yunpanIndex:1,//分页下标
      yunpanObj:{
        type:1, //1 为 云盘上传 2为文件上传
        files:[],
      },
      yunpanAllCheck:false,
      yunpanArr:[],//临时数组

      fileList1:[],//文件上传数组
      transferShareVisible:false, //传输/分享弹窗
      shareQrVisible:false, //扫码分享弹窗
      shareEmailVisible:false, //邮箱分享弹窗
      sendEmail:[{email:''}], //分享至的邮箱

      percentage:0,//上传进度
      timer:null,

      share_qr:'',//扫码分享的二维码

      //评论
      isEditComment:false, //是否评论
      comment:'',
      //评论弹幕
      danmus: [],
      //弹幕开关
      commentForm:false,
      //弹幕显示
      showCommentForm:false,
      //搜索
      searchVal:'',//搜索的文件
      searchPage:1,//搜索下标
      searchCount:null,
      searchList:[],//搜索数组
      searchState:false,//查看详情


      link:'',
      link_code:'',
      share_url:'',//分享完整链接包含id
      share_id:0,//	分享链接的主键id

      random:'',
      // filesize:0,//文件字节
      uploadLoaded:0,//上传字节
      uploadSize:0,//上传成功 记录成功文件的字节
      speed:'',//渲染的上传网速
      temSpeed:'',//临时渲染的上传网速
      codeState:true,//发送验证码
      temporaryCode:'',//临时验证码
      token:this.$store.state.token || localStorage.getItem('token'),
      homeInfo:{},//首页参数
      backgroundList:[],//背景图
      uploadBefore:{},//上传前参数
      uploadFileList:[],//最后上传的文件数组
      uploadTitle:'',//传输头部信息
      uploadTitleState:false,//传输头部状态
      uploadTitleVal:'',//
      uploading:false,//上传状态
      fileAllSize:0,//文件总体大小
      fileAllNum:0,//文件数量
      closeSetInterVal:true,//定时器

      reportTags:[],//举报标签数组

      newsList:[],
      webInfo:{},
      footerLinkList:[],//底部icp数组
      shardSize:'',//单片大小
      t0 : new Date(), // 文件开始上传时间
      showPreviewFile:false,//文件预览组件
      fileUrl: '',//文件地址
      fileType:'',//文件类型
      sensitiveKeywordList:[],
      copy1:false,//复制链接按钮
      copy2:false,//复制取件码按钮
      posterUrl:'',//海报地址
      time:'',//时间
      cancel:'',//中断上传
      freeDown:0,//一小时之内是否免流量
      freePreview:1,//是否花费流量预览
      packZipTime:1,//打包时间
      fileSizeTotalInfo:{},
      isZip:false,//是否需要打包
      lastTime:0,
      lastSize:0,
      mailToAddress:'',
    };
  },
  filters:{
    //过滤有效期
    validPeriodType(val){
      if(val == 0) return '永久有效'
      else{
        return  val + '天'
      }
    },
    getVipType(val){
      if(val == 1){
        return require('@assets/img/index/icon-vip5.gif')
      }
      if(val == 2){
        return require('@assets/img/index/icon-vip5.gif')
      }
      if(val >= 3){
        return require('@assets/img/index/icon-vip5.gif')
      }
    },
    //过滤数据

  },
  components: {
    swiper,
    swiperSlide,
    CircleLoading,
    handelPopup,
    previewFileComponents,
    vueDanmaku,
    overallPopup,
    kcHeader
  },

  directives: {
    clickoutside,drag
  },

  computed: {
    ...mapState({
      // speed:state => state.homeSpeed
    }),
    //选中的数量
    checkLength(){
      // console.log('homememe',this.yunpanList)
      return this.fileList.filter(item=>{
          return item.isCheck == true
      }).length
    },
    //取件选中文件的数量
    checkDownloadLength(){
      return this.folderList.filter(item=>{
        return item.state
      }).length
    },
    //取件全选
    isDownloadCheckAll(){
      //判断全选
        if(!this.folderList.length){
          return false
        }
        return this.folderList.length===this.checkDownloadLength
    },
    //选择文件的大小
    checkSize(){
      let size = 0
      //切换页时也保留选择
      // this.yunpanList.filter(item=>{
      //   if(item.isCheck == true ){
      //     size += item.size
      //   }
      //   return size
      // })

      //只保留当前页选择
      this.fileList.filter(item=>{
        if(item.isCheck == true ){
          size += item.size
        }
        return size
      })

      return size
    },
    //获取所有需要上传文件的字节
    fileSize(){
      let size = 0;
      this.fileList1.filter(item=>{

        return size +=item.size
      })
      return size
    },
    //选中的需要下载文件的总大小
  },

  created() {
    this.token = localStorage.getItem('token') || this.$store.state.token
    let url = window.location.hash
    // let share_url = window.location.href
    //推广人
    let type = url.substring(url.lastIndexOf('?') + 1,url.lastIndexOf('='))
    let id = url.substr(url.lastIndexOf('=')+1)
    //分享连接
    let shareId = url.substr(url.lastIndexOf('=')+1)
    // alert(type)
    // alert(shareId)
    console.log('type',type)
    console.log('id',id)
    console.log('shareId',shareId)
    if(type == 'id'){
        this.leader_id = id
    }
    if(type == 's'){
      this.hadelCollect(shareId)
    }
    //获取网站基本信息
    this.getBasicData()

    //获取首页参数
    this.getHomeData()
    //获取举报标签
    this.getReportData()
    // //获取云盘数据
    // this.getYpyData('',1)
    clearInterval(this.closeSetInterVal)
    //获取用户信息
    this.getUser()
    //获取链接详情
    this.getNewsListData()
    //我的文件发送
    if(this.$route.params.arr){
      this.fileAllSize = JSON.parse(this.$route.params.size_total)
      this.getYpyUploadInfo()
      this.submityunpanData(JSON.parse(this.$route.params.arr))
    }
    this.getNowDate()
  },
  mounted() {

  },
  beforeCreate() {
    clearInterval(this.closeSetInterVal)
  },

  watch:{
    uploadLoaded(newVal,oldVal){
      // let speed = newVal - oldVal
      // 上一次计算的文件大小
      let lastSize = 0
      var nowTime = new Date().getTime()
      var intervalTime = (nowTime - this.lastTime)  // 时间单位为毫秒，需转化为秒
      var intervalSize = newVal - this.lastSize
      this.lastTime = nowTime

      /*重新赋值以便于下次计算*/
      this.lastTime = nowTime
      this.lastSize = newVal
      var speed = intervalSize /intervalTime
      var units = '' // 单位名称

      if(speed > 1024){
        speed = (speed / 1024).toFixed(2)
        units = 'M/s'
      }else{
        units = 'kb/s'
        speed = speed < 0 ? 1  : speed.toFixed(2)
      }
      this.temSpeed = speed + units
      // this.speed = speed + units

      // this.$store.commit('getSpeed',speed + units)
      let upload_speed = Math.round(((this.uploadSize + newVal) * 100) / this.fileSize )
      this.percentage = upload_speed >= 100 ? 100:upload_speed
      this.percentage = this.percentage <= 99 ?upload_speed:99
      // let s = Math.round(
      //     (speed / 1024) * 10
      // );
      //
      // if (s > 1024) {
      //   this.speed = (s / 1024).toFixed(2) + "M/s";
      // } else {
      //   this.speed = s <0 ? 1 + "kb/s" : s + 'kb/s';
      // }
    },
    downLoadList(newVal,oldVal){
      let size = 0
      newVal.filter(item=>{
        size +=item.size
        return size
      })
      this.downLoadFileSize = size
    },
    //监听上传弹窗 每次开启重置选项
    transferShow(){
      this.searchPermission = false
    },
    showLogin(newVal){
      if(newVal){
        this.closeSetInterVal = setInterval(()=>{
          this.wxCodeLogin()
        },1000)
      }else{
        window.clearInterval(this.closeSetInterVal)
      }
    },
    //切换支付方式 1微信 2支付宝
    payIndex(newVal){
      this.payIndex = newVal
      this.handelPayUrl(this.share_id)
      window.clearInterval(this.timer)
    },
    showPrice2(newVal){
      if(newVal){
        window.clearTimeout(this.timer)
        this.showPrice = true
      }else{
        this.showPrice = false
      }
    }
  },
  methods: {
    codeVerify() {
      this.showLogin = !this.showLogin;
      this.showSearchResult = false
      if (this.isFirstClick) {
        this.getWechatLoginQrCode()
        this.isFirstClick = false
      }
    },
    //获取当前时间
    getNowDate () {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.time = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
      // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    //过滤输入框
    limitInput(){
      this.price =
          ("" + this.price) // 第一步：转成字符串
              .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
              .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
              .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
              .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有
    },
    //获取新闻详情
    getNewsListData() {
      getNewsList().then(res=>{
        this.newsList = res.data.list
        console.log('this.newsList',this.newsList)
      })
    },
    //用户信息
    getUser(){
      getUserInfo().then(res=>{
        if(res.code == 1){
          this.userInfo = res.data
          if(this.userInfo.vip_expiry_time){
            let time =this.userInfo.vip_expiry_time.split(' ')
            this.userInfo.vip_expiry_time = time[0]
          }
        }else{
          this.token = ''
          this.$store.state.token = ''
          localStorage.removeItem('token')
        }
      })
    },
    speedProgress(percentage){
      return percentage +'%已使用'
    },
    //切换登录
    switchLogin(index){
      if(index == 1){
        this.showQr = false
        this.showfont = false
        setTimeout(()=>{
          this.showQr1 = true
          this.showfont = true
        },500)
        this.closeSetInterVal = setInterval(()=>{
          this.wxCodeLogin()
        },1000)
      }else{
        this.showQr1 = false
        this.showForgetPwd = false
        this.showfont = false
        clearInterval(this.closeSetInterVal)
        setTimeout(()=>{
          this.showQr = true
          this.showfont = true
        },500)
      }
    },
    //获取网站基本信息 例:账号密码 容器地址
    getBasicData(){
      getWebInfo().then(res=> {
        this.webInfo = res.data
        this.footerLinkList = res.data.footer_link_list
        for(let item in this.footerLinkList){
          this.$set(this.footerLinkList[item],'jump_type',1)
        }

        this.$store.commit('getWebInfo', this.webInfo)
        window.SeoUpdate(`${this.webInfo.title}`, `${this.webInfo.keywords}`, `${this.webInfo.description}`);
        // (function (item) {
        //   console.log('item',item)
        //   var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        //   link.type = 'image/x-icon';
        //   link.rel = 'shortcut icon';
        //   link.href = item.ico_img;
        //   document.getElementsByTagName('head')[0].appendChild(link);
        // })(this.webInfo)
      })
    },
    //获取首页参数
    getHomeData(){
      getHomeInfo().then(res=>{
        this.homeInfo = res.data
        this.showCardSearch = res.data.enabled_search == 0 ?false : true
        this.backgroundList = []

        setTimeout(()=>{
          for(let item in this.homeInfo.background_list){
            this.backgroundList.push({
              url:this.homeInfo.background_list[item].url,
              img:baseUrl.url +this.homeInfo.background_list[item].img
            })
          }
        },0)
        this.$set(this.swiperOption,'loop',true)
        let time = this.homeInfo.background_time * 1000
        this.$set(this.swiperOption.autoplay,'delay',time)
      })
    },
    //获取上传又拍云参数
   async getYpyUploadInfo(){
      await uploadBefore().then(res=>{
        this.uploadBefore = res.data
        //敏感关键字
        this.sensitiveKeywordList = res.data.violation_words_list
        this.uploadBefore.upload_size_max = this.$editE(this.uploadBefore.upload_size_max)
        this.uploadBefore.member_flow_over = this.$editE( this.uploadBefore.member_flow_over)
        this.uploadBefore.save_key =   res.data.upload_path + '/' + res.data.uuid_path +'/' + res.data.time_path +'/'
        this.$store.state.uploadBefore = this.uploadBefore
        this.uploadBefore.visitor_upload = res.data.visitor_upload
        let id  = this.token? this.uploadBefore.expiry_day_list[1] : this.uploadBefore.expiry_day_list[0]
        let val  = this.token? this.uploadBefore.expiry_day_list[1] : this.uploadBefore.expiry_day_list[0]

        this.$set(this.validPeriod,'id',id)
        this.$set(this.validPeriod,'val',val)
        this.$store.commit('getuploadBefore',this.uploadBefore)
      })
    },
    //获取举报标签
    getReportData(){
      getReportTags().then(res=>{
        if(res.code == 1){
          this.reportTags  = res.data.tags_list
          for(let item in this.reportTags){
            this.$set(this.reportTags[item],'states',false)
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //获取微信二维码
    getWechatLoginQrCode(id){
      this.qrErr = false
      let params = {
        leader_id:id,
      }
      getWxCode(params).then(res=>{
        console.log(res)
        this.QRImgUrl = res.data.qr_url
        this.$store.state.wxtoken = res.data.token
        setTimeout(() => {
          this.showExpiredMask();
        },res.data.expire_seconds * 1000)
      })
    },
    showExpiredMask() {
      this.qrErr = true
      this.wxCodeLogin()
    },
    //扫码登录
    wxCodeLogin(){
      let params = {
        token:this.$store.state.wxtoken
      }
      WxCodeLogin(params).then(res=>{
        if(res.code == 1){
          this.$store.state.token = res.data.token
          localStorage.setItem('token',res.data.token)
          this.showLogin = false
          // this.$forceUpdate()
          this.getYpyUploadInfo()
          this.token = res.data.token
          this.getUser()
          if(this.showTake){
            this.hadelCollect()
          }
          this.showLogin = false
          this.$Message.success(`${res.msg}`)
          window.clearInterval(this.closeSetInterVal)
          //中断上传
          if(this.cancel){
            this.cancel('')
            this.transferShareVisible = false
            this.card = true
          }
        }else{
          if(res.data.scan_status == 1){
            this.$Message.success(`${res.msg}`)
            window.clearInterval(this.closeSetInterVal)
          }
        }
      })
    },
    //获取云盘数据
    async getYpyData(data,type,index){
      //获取上传前参数
      await this.getYpyUploadInfo()
      console.log('this.uploadBefore.visitor_upload',this.uploadBefore.visitor_upload)
      switch (this.uploadBefore.visitor_upload ){
        case 1 :
          this.showLogin = true
          this.$Message.error('请登录')
            return false
          break
      }
      if(this.homeInfo.open_share_status == 0){
        this.$Message.error('管理员已关闭上传功能，开放日期留意官方公告！')
        return  false
      }

      let params = {
        prev_id:data?data.id:'',
        operate:1,
        page:this.yunpanIndex,
        limit:50,
      }
      this.showSendFile = false
      localStorage.setItem('prev_id',data?data.id:'')
      let arr = []
      console.log('index',this.yunpanIndex)
      if(type == 1){
        getPanList(params).then(res=>{
          if(data.state){
            arr = res.data.list
            for (let item in arr){
              this.$set(arr[item],'isCheck',false)
              this.fileList.push(arr[item])
            }
          }else{
            this.fileList = res.data.list
            for(let item in this.fileList){
              this.$set(this.fileList[item],'isCheck',false)
            }
            this.yunpanIndex = 1
            this.yunpanAllCheck = false
          }
          //查询新数据时也保留选项
          // for(let item in this.yunpanList){
          //   for(let data in this.fileList){
          //     if(this.yunpanList[item].id == this.fileList[data].id){
          //       this.fileList[data].isCheck = this.yunpanList[item].isCheck
          //     }
          //   }
          // }

          if(this.fileList.every(item=> item.isCheck == true)){
            this.yunpanAllCheck = true
          }
          this.cloudDiskVisible = true
          if(data.state == false || data.state == null || data.state == undefined){
            if(res.data.prev_id == null){
              this.yunpanTitle = []
              this.yunpanIndex = 1
              this.yunpanTitle.push({
                title:'全部',
                id:''
              })
            }else{
              let state = false
              for(let item in this.yunpanTitle){
                if(this.yunpanTitle[item].id == data.id ){
                  state = true
                  this.yunpanTitle.splice(index+1,this.yunpanTitle.length - item)
                }
              }
              if(!state){
                this.yunpanTitle.push({
                  id:data.id,
                  title:data.name
                })
              }
            }
          }
        })
      }
    },
    isWeChatPC() {
      var ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('micromessenger') != -1 && (ua.indexOf('windowswechat') != -1 || ua.indexOf('macwechat') != -1);
    },
    //滚动到底部时触发
    getYunpanPage(e){
      if (e.srcElement.scrollTop + e.srcElement.clientHeight == e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0) {
        this.$nextTick(()=>{
          this.yunpanIndex +=1
          let prev_id = localStorage.getItem('prev_id')
          this.getYpyData({id:prev_id,state:true},1,)
        })

      }
    },
  btnClone() {
      setTimeout(() => {
        this.showCardSearch1 = true
        this.searchVal = ''
      },0)
    },
    //发送文件
    sendoutFile(){
      if(this.homeInfo.enabled_visitor_upload == 0 && this.token == ''){
        this.$Message.error('请登录后使用')
        this.showLogin = true
        this.showdownload = false
        if (this.isFirstClick) {
          this.getWechatLoginQrCode()
          this.isFirstClick = false
       }
      }else{
        this.showSendFile=!this.showSendFile
        this.showPrice1 = true
      }
    },
    //允许他人操作
    choiceDropdown(id,val){
      this.$set(this.providePermission,'id',id)
      this.$set(this.providePermission,'val',val)
    },
    //有效期
    choiceTimeDropdown(id,val){
      this.$set(this.validPeriod,'id',id)
      this.$set(this.validPeriod,'val',val)
    },
    //上传文件 input
   async uploadFile(e,type){
      console.log('event',e)
      if(this.homeInfo.open_share_status == 0){
        //每次上传文件后清空上传
        e.target.value = ''
        this.$Message.error('管理员已关闭上传功能，开放日期留意官方公告！')
        return  false
      }
      //获取上传又拍云前参数
      await this.getYpyUploadInfo()
      let _this = this
      let size = 0
      let state = false
      let files = e.target.files
      //拆分数据 只获取file 对象
      let fileArr = []
      let folder = []
      let isUpload = true
      for(let item in files){
        if(files[item].name && files[item].size ){
          fileArr.push(files[item])
        }
      }
      console.log('fileArr',fileArr)
      fileArr.forEach(item=>{
        if(this.$filterKeyword(item.name,this.sensitiveKeywordList)){
          console.log('包含敏感')
          this.fileList1 = []
          this.transferShow = false
          this.card = true
          isUpload = false
          return  this.$Message.error('该文件涉嫌违反国家相关法律法规，禁止上传和分享！')
        }
        let obj = {
          name:item.webkitRelativePath?item.webkitRelativePath.substring(0,item.webkitRelativePath.indexOf('/')):item.name,
          files:[],
          size:item.size,
          md5:'',
          suffix:item.webkitRelativePath?'folder':item.name.substring(item.name.lastIndexOf('.') + 1),
        }
        size += item.size
        obj.files.push({
              file:item,
              md5:'',
              name:item.name,
              size:item.size
            })
        folder.push(
            {
              file:item,
              md5:'',
              name:item.name,
              size:item.size
            }
        )
        for(let file in _this.fileList1){
          if(_this.fileList1[file].name == (type == 'file'?item.name:obj.name)){
            _this.fileList1[file].files = type == 'file'? obj.files:folder
            _this.fileList1[file].size = size
            state = true
            break
          }
        }
        if(!state){
          _this.fileList1.push(obj)
        }
      })
     //头部名称 文件超出2个 显示等
      if(this.fileList1.length > 1){
        this.uploadTitle = this.uploadTitleVal == "" ?(this.fileList1[0].name || this.fileList1[0].files[0].file.name)  :this.uploadTitleVal
      }else{
        this.uploadTitle = this.fileList1.length == 0? '文件传输': this.fileList1[0].name || this.fileList1[0].files[0].file.name + '等' + this.fileList1.length + '个文件'
      }

     //限制文件上传大小 超出禁止上传
     if(this.uploadBefore.visitor_upload == 1 ){
        if((this.fileSize / 1024 ) > this.uploadBefore.upload_size_max){
           this.fileList1 = []
           this.showFileTransfer = false
           this.transferShow = false
           this.card = true
            this.showLogin = true
           return this.$Message.error('请登录后使用')
        }
      }else{
        if((this.fileSize / 1024 ) > this.uploadBefore.upload_size_max){
          this.fileList1 = []
          this.showFileTransfer = false
          this.transferShow = false
          this.card = true
          return this.$Message.error('当前账户云盘空间不足，请扩容后使用')
        }
      }
      this.flow = 1
      this.isProvideFlow = false
      //每次上传文件后清空上传
      e.target.value = ''
      //是否可以上传
      if(!isUpload) return  false
      //上传弹窗
      this.transferShow = true
      //外部选择上传弹窗
      this.showSendFile = false
      //第一个上传弹窗 可移动的那个
      this.card = false
      //文件上传
      this.yunpanObj.type = 2
      //登录窗口
      this.showLogin = false
      //搜索弹窗
      this.showSearchResult = false
      console.log('上传进度',this.percentage)
   },
    //修改文件头部信息
    reviseUploadTitle(){
      if(this.fileList1.length > 1){
        this.uploadTitleState = true
        this.uploadTitleVal = this.uploadTitle
      }
    },
    //输入框修改头部信息
    handelInput(){
      this.uploadTitle = this.uploadTitleVal
      this.uploadTitleState = false
    },
    //删除文件
    delfile(index){
      this.fileList1.splice(index,1)
      if(this.fileList1.length > 1){
        this.uploadTitle = this.uploadTitleVal == ''? (this.fileList1[0].name || this.fileList1[0].files[0].file.name) + '等':this.uploadTitleVal
      }else if(this.fileList1.length == 0){
        this.uploadTitle = ''
        this.card = true
        this.transferShow = false
        this.fileAllSize = ''
      }else{
        this.uploadTitleVal = ''
        this.uploadTitle = this.fileList1[0].name || this.fileList1[0].files[0].file.name
      }
    },
    //再传一次 //关闭传输弹窗 并 清空传输列表
    againUpload(){
      this.transferShareVisible = false
      this.card = true
      this.fileList1 = []
      this.link = ''
      this.link_code = ''
      this.percentage = 0
      this.uploadSize = 0
      this.isTransferAgree = false
      this.shareQrVisible = false
      this.shareEmailVisible = false
      this.$store.state.uploading = false
      this.uploading = false
    },
    //获取验证码
    getVerificationCode(type){
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let time = 60
      let params = {
      }
      if(type == 1){
        if(!this.codeState) return false
        if(this.phone == '' ){
          return this.$Message.error('手机号不能为空')
        }else if(this.phone.length <= 10 ||!reg.test(this.phone ) ) {
          return this.$Message.error('请填写正确的手机号')
        }
          params.phone = this.phone
          let closeSetInterval =  setInterval(()=>{
          if(time == 0){
            clearInterval(closeSetInterval)
            this.codeText = '发送验证码'
            this.codeState = true
          }else{
            this.codeState = false
            time -= 1
            this.codeText = time + '秒后重新发送'

          }
        },1000)
      }


      if(type == 2){
        if(!this.codeState) return false
        if(this.fphone == '' ){
          return this.$Message.error('手机号不能为空')
        }else if(this.fphone.length <= 10 ||!reg.test(this.fphone ) ) {
          return this.$Message.error('请填写正确的手机号')
        }

        params.phone = this.fphone
        let closeSetInterval =  setInterval(()=>{
          if(time == 0){
            clearInterval(closeSetInterval)
            this.fcodeText = '发送验证码'
            this.codeState = true
          }else{
            this.codeState = false
            time -= 1
            this.fcodeText = time + '秒后重新发送'

          }
        },1000)

      }
      getCode(params).then(res=>{
        // this.temporaryCode = res.msg.substr(res.msg.length - 4)
        if(res.code == 1){
          return this.$Message.success(`${res.msg}`)
        }else{
          return this.$Message.error(`${res.msg}`)
        }
      })

    },
    //最后上传
    async submitUpload(){
      let index = 0 //定义下标
      let promiseArr = [] //promise数组
      let uploadFileList = []
      if(!this.isTransferAgree){
        this.$Message.error('请勾选协议')
        return false
      }
      const size = this.fileAllSize ?  this.fileAllSize * this.flow : this.fileSize * this.flow / 1000
      if(this.uploadBefore.member_flow_over < size && this.isProvideFlow) {
        this.uploading = false
        this.$store.state.uploading = false
        this.$Message.error('流量余额不足，请先充值')
        return false
      }
      if(this.yunpanObj.type == 2){
        //上传中状态
        this.uploading = true
        this.$store.state.uploading = true
        this.setTime()
        this.speed = ''
        if(this.uploadTitle.lastIndexOf('等') != -1){
          this.uploadTitle = this.uploadTitle.substring(0,this.uploadTitle.lastIndexOf('等'))
          // console.log('有执行',this.uploadTitle)
          // console.log('等等等等',this.uploadTitle.lastIndexOf('等'))
        }

        //处理最后上传数组 把所有文件拆分出来
        for(let item in this.fileList1){
          for(let file in this.fileList1[item].files){
            uploadFileList.push({
              name:this.fileList1[item].files[file].file.webkitRelativePath?this.fileList1[item].files[file].file.webkitRelativePath:this.fileList1[item].files[file].file.name,
              size:this.fileList1[item].files[file].file.size
            })
          }
        }
        let t0 =   new Date(); // 文件开始上传时间
        let data = {
          visitor_upload:this.uploadBefore.visitor_upload, //是否为游客 0 否 1是
          time_path:this.uploadBefore.time_path, //上传前接口返回
          uuid_path:this.uploadBefore.uuid_path,//上传前接口返回
          upload_path:this.uploadBefore.upload_path,//上传前接口返回
          prev_id:null,//上层文件夹id，默认为null
          open_search:this.searchPermission?1:0,
          need_pay:this.price?1:0,
          price:this.price,
          free_num:this.isProvideFlow?this.flow:0,
          look_type:this.providePermission.id,
          expiry_day:this.validPeriod.id,
          email:'',
          remark:this.supplement,
          share_url:'/#/home',
          title:this.uploadTitleVal?this.uploadTitleVal:this.fileList1[0].name,
          files_list:uploadFileList
        }
        // console.log('data',data)
        // console.log('五毒温度无毒无度的五',this.isProvideFlow)

          this.transferShareVisible = true
          this.transferShow = false

          this.$store.state.share_id = this.share_id
          this.$store.state.link_code = this.link_code
          localStorage.setItem('share_id',this.share_id)
          // this.share_url = res.data.share_url
          // this.$notify({
          //   title: `${res.msg}`,
          //   message: '这是一条成功的提示消息',
          //   duration:2000,
          //   type: 'success'
          // });
          for(let item in this.fileList1){
            for(let file in this.fileList1[item].files){
              // console.log('this.fileList1[item].files[file].file',this.fileList1[item].files[file].file)
              // let md5 =  this.$md5(this.fileList1[item].files[file].file)
              // console.log('md5',md5)
              let obj = this.$getSign(
                  '',
                  this.uploadBefore.save_key,
                  this.fileList1[item].files[file].md5,
                  this.fileList1[item].files[file].file.size,
                  this.fileList1[item].files[file].file.webkitRelativePath?this.fileList1[item].files[file].file.webkitRelativePath:this.fileList1[item].files[file].file.name,
                  this.uploadBefore.bucket_path,
                  this.uploadBefore.upload_path,
                  this.uploadBefore.uuid_path,
                  this.uploadBefore.time_path
              )
              // upload_path"+/+"uuid_path"+/+"time_path"

              promiseArr[index] = await new Promise( async (resolve)=>{
                if(this.fileList1[item].files[file].file.size < (50 * 1024 * 1024)){
                  let directlyFile =  await this.directlyFile(this.fileList1[item].files[file].file)
                  resolve(directlyFile)
                }else{
                  let headers =  await this.initializeFile(this.fileList1[item].files[file].file,obj)
                  let success = await this.handelchunkFile(headers,this.fileList1[item].files[file].file)
                  resolve(success)
                }
                index++
              })
            }
          }
          Promise.all(promiseArr).then(res=>{
            uploadFile(data).then(  async res=>{
              if(res.code ==1){
                this.link = res.data.share_url
                this.link_code = res.data.pickup_pwd
                this.share_id = res.data.share_id
                await this.createQRImg(this.share_id )
                window.clearInterval(this.timer)
                this.speed = '传输完成'
                this.percentage = 100
                this.uploading = false
                this.$store.state.uploading = false
                this.isProvideFlow = false, //是否自定义提供流量
                this.providePermissionVisible = false, //允许他人弹窗
                this.flow = '1',//提供的流量
                this.showPrice1 = false
                this.price = 0
                this.$set(this.providePermission,'id',1)
                this.$set(this.providePermission,'val','下载、转存及预览')
              }else{
                this.uploading = false
                this.$store.state.uploading = false
                this.transferShareVisible = false
                this.card = true
                this.fileList1 = []
                this.link = ''
                this.link_code = ''
                this.percentage = 0
                this.uploadSize = 0
                window.clearInterval(this.timer)
                this.isTransferAgree = false
                this.shareQrVisible = false
                this.shareEmailVisible = false
                this.$Message.error(`${res.msg}`)
              }
            })
          })


      }else{
        console.log('云盘上传')
        for(let item in this.fileList1){
          uploadFileList.push({
            type:this.fileList1[item].type,
            id:this.fileList1[item].id,
          })
        }

        if(this.uploadTitle.lastIndexOf('等') != -1){
          this.uploadTitle = this.uploadTitle.substring(0,this.uploadTitle.lastIndexOf('等'))
        }
        let data = {
          share_url:'/#/home',
          title:this.uploadTitle,
          prev_id:null,//上层文件夹id，默认为null
          open_search:this.searchPermission?1:0,
          need_pay:this.price ?1:0,
          price:this.price,
          free_num:this.isProvideFlow?this.flow:0,
          remark:this.supplement,
          look_type:this.providePermission.id,
          expiry_day:this.validPeriod.id,
          files_list:uploadFileList
        }

        console.log('data',data.title)
        uploadFileByPan(data).then(res=>{
          if(res.code == 1){
            this.transferShareVisible = true
            this.transferShow = false
            this.link = res.data.share_url
            this.link_code = res.data.pickup_pwd
            this.share_id = res.data.share_id
            this.percentage = 100
            this.speed = '传输完成'
            this.isProvideFlow = false, //是否自定义提供流量
            this.providePermissionVisible = false, //允许他人弹窗
            this.flow = '1',//提供的流量
            this.showPrice1 = false
            this.price = 0
            this.$set(this.providePermission,'id',1)
            this.$set(this.providePermission,'val','下载、转存及预览')
            this.createQRImg(this.share_id)
          }else{
            this.$Message.error(`${res}`)
          }

        })
      }
    },
    //上传 网速
    setTime(){
      //每隔一分钟运行一次保存方法
      this.timer = setInterval(()=>{
        this.saveList();
      },1000)
    },
    saveList(){
      this.speed = this.temSpeed
    },
    //直接上传 如果文件小于1m 直接上传 不需要分片
    async directlyFile(file){
      let that = this
      let formdata = new FormData
      formdata.append('file',file)
      let name  = file.webkitRelativePath?file.webkitRelativePath:file.name
      return await new Promise(  resolve => {
        let obj = that.$getSign(
            '',
            that.uploadBefore.save_key,
            '',
            file.size,
            name,
            that.uploadBefore.bucket_path,
            that.uploadBefore.upload_path,
            that.uploadBefore.uuid_path,
            that.uploadBefore.time_path
        )
        axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, file,{
          onUploadProgress:(progressEvent)=>{
            this.uploadLoaded = progressEvent.loaded
          },
          headers: {
            'Authorization':obj.sign,
            "x-date":obj.date,
          },
          cancelToken: new axios.CancelToken((cancel) => {
            that.cancel = cancel
          })
        }).then(res => {
          this.uploadSize += file.size
          resolve(res)
        })
      })
    },
    //初始化文件分块任务
    async initializeFile(file,obj){
      let that = this
      let name  = file.webkitRelativePath?file.webkitRelativePath:file.name

      return await new Promise(  resolve => {
        axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, {},{
          headers: {
            'Authorization':obj.sign,
            "x-date":obj.date,
            'X-Upyun-Multi-Stage':'initiate',
            'X-Upyun-Multi-Length':file.size,
            // 'X-Upyun-Multi-Part-Size': 10485760,
            'X-Upyun-Multi-Part-Size': 10 * 1024 * 1024
          },
          cancelToken: new axios.CancelToken((cancel) => {
            that.cancel = cancel
          })
        }).then(res => {
          let headers = res.headers

          this.shardSize = headers['x-upyun-next-part-size']
          resolve(headers)
        })
      })
    },
    //分块上传任务
    async handelchunkFile(data,file){
      let chunkSize =  data['x-upyun-next-part-size'] //总大小
      let item;//下标
      let shardSize  = this.shardSize //单片大小
      let filedata;//数据
      let that = this
      var headers;
      var start;
      let total;
      var end;
      let name  = file.webkitRelativePath?file.webkitRelativePath:file.name

      return await new Promise( async resolve => {
        async function endupload(data,file){
          let obj = that.$getSign(
              '',
              that.uploadBefore.save_key,
              '',
              file.size,
              name,
              that.uploadBefore.bucket_path,
              that.uploadBefore.upload_path,
              that.uploadBefore.uuid_path,
              that.uploadBefore.time_path
          )
          axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, {},{
            headers: {
              'Authorization':obj.sign,
              "x-date":obj.date,
              'X-Upyun-Multi-Stage':'complete',
              'X-Upyun-Multi-Uuid': data['x-upyun-multi-uuid'],
              'X-Upyun-Part-Id':data['x-upyun-next-part-id'],
              'X-Upyun-Multi-Part-Size': data['x-upyun-next-part-size'],
            },
            cancelToken: new axios.CancelToken((cancel) => {
              that.cancel = cancel
            })
          }).then(res => {
            console.log('上传完成res',res)
            that.uploadSize += total
            resolve(res)
          })
        }
        async function upload(headers,file){
          item = headers['x-upyun-next-part-id']
          //处理最后一片 切片
          if ((headers['x-upyun-remain-size'] * 1) < (that.shardSize) * 1) {
            start = item * (that.shardSize * 1);
            end = file.size;
            filedata =  file.slice(start,end)
          } else {
            start = item * (that.shardSize * 1);
            end = (start * 1)  + (that.shardSize * 1);
            filedata =  file.slice(start,end)
          }

          let obj = that.$getSign(
              '',
              that.uploadBefore.save_key,
              '',
              file.size,
              name,

              that.uploadBefore.bucket_path,
              that.uploadBefore.upload_path,
              that.uploadBefore.uuid_path,
              that.uploadBefore.time_path
          )

          await  axios.post(`https://v0.api.upyun.com${  '/'+ that.uploadBefore.bucket_path + that.uploadBefore.upload_path +'/'+that.uploadBefore.uuid_path+'/'+that.uploadBefore.time_path + '/' }${name}`, filedata,{
            onUploadProgress:(progressEvent)=>{
              that.uploadLoaded = progressEvent.loaded
              if(progressEvent.loaded == progressEvent.total){
                total = progressEvent.total
                that.uploadLoaded = progressEvent.loaded
              }
            },
            headers: {
              'Authorization':obj.sign,
              "x-date":obj.date,
              'X-Upyun-Multi-Stage':'upload',
              'X-Upyun-Multi-Uuid': headers['x-upyun-multi-uuid'],
              'X-Upyun-Part-Id':headers['x-upyun-next-part-id'],
              'X-Upyun-Multi-Part-Size': headers['x-upyun-next-part-size'],
            },
            cancelToken: new axios.CancelToken((cancel) => {
              that.cancel = cancel
            })
          }).then( async res => {
            headers = res.headers
            if(headers['x-upyun-next-part-id']  == -1 && headers['x-upyun-next-part-size'] == -1 ){
              await  endupload(res.headers,file)
            }else{
              that.uploadSize +=  (shardSize * 1)
              await upload(res.headers,file)
            }
          })
        }
        await upload(data,file)
      })


    },

    //登录/注册
    submit(){
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let params = {}
      if(this.loginType == 1){
        if(this.phone == ''){
          return this.$Message.error('手机号不能为空')
        }else if(this.phone.length <= 10 ||!reg.test(this.phone )) {
          return this.$Message.error('请填写正确的手机号')
        }
        if(this.code == ''){
          return this.$Message.error('验证码不能为空')
        }else if(this.code.length>4){
          return this.$Message.error('请填写正确的验证码')
        }
        // if(this.code != this.temporaryCode ){
        //   return this.$Message.error('验证码错误')
        // }
        if(!this.isAgree){
          return this.$Message.error('请勾选协议')
        }
        params.phone = this.phone,
        params.msg_code = this.code
        params.leader_id = this.leader_id
        //中断上传
        if(this.cancel){
          this.cancel('')
          this.transferShareVisible = false
          this.card = true
          this.$store.state.uploading = false
          this.uploading = false
        }
        phoneLogin(params).then(res=>{
          if(res.code == 1){
            this.$store.state.token = res.data.token
            localStorage.setItem('token',res.data.token)
            this.token = res.data.token
            this.showLogin = false
            // this.$forceUpdate()
            this.getUser()
            this.getYpyUploadInfo()
            if(this.showTake){
              this.hadelCollect()
            }
            this.$Message.success(`${res.msg}`)
            // this.$router.push('/ucenter')
          }else{
            this.$Message.error(`${res.msg}`)
          }


        })
      }
      if(this.loginType == 2){
        if(this.phone == ''){
          return this.$Message.error('手机号不能为空')
        }else if(this.phone.length <= 10 ||!reg.test(this.phone )) {
          return this.$Message.error('请填写正确的手机号')
        }
        if(this.password == ''){
          return this.$Message.error('密码不能为空')
        }
        params.phone = this.phone,
        params.password = this.password
        pwdLogin(params).then(res=>{
          console.log('res',res)
          if(res.code == 1){
            this.$store.state.token = res.data.token
            localStorage.setItem('token',res.data.token)
            this.token = res.data.token
            this.showLogin = false
            // this.$forceUpdate()
            this.getUser()
            this.getYpyUploadInfo()
            this.$Message.success(`${res.msg}`)
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }
    },
    //忘记密码提交
    submitFindPwd(){
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      let time = 60
      if(this.fphone == ''){
        return this.$Message.error('手机号不能为空')
      }else if(this.fphone.length <= 10 ||!reg.test(this.fphone )) {
        return this.$Message.error('请填写正确的手机号')
      }
      if(this.fcode == ''){
        return this.$Message.error('验证码不能为空')
      }else if(this.code.length>4){
        return this.$Message.error('请填写正确的验证码')
      }
      if(this.fpassword == ''){
        return this.$Message.error('新密码不能为空')
      }
      if(this.fpassword2 == ''){
        return this.$Message.error('确定密码不能为空')
      }
      if(this.fpassword != this.fpassword2){
        return this.$Message.error('两次密码输入不一致')
      }
      let data = {
        phone:this.fphone,
        msg_code:this.fcode,
        pwd:this.fpassword,
        re_pwd:this.fpassword2
      }
      //中断上传
      if(this.cancel){
        this.cancel('')
        this.transferShareVisible = false
        this.card = true
      }
      findPwd(data).then(res=>{
        console.log('res',res)
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })

    },
    //提交举报
    submitReports(){
      let arr = this.reportTags.filter(item=>{
        if (item.states == true) return item
      })
      let data = {
        share_id:this.$store.state.share_id || localStorage.getItem('share_id'),
        tags_list:arr
      }
      SubmitReport(data).then(res=>{
        if(res.code == -1) {
          this.showLogin = true
        }
        if(res.code == 1){
          this.showReport = false
          let state = false
          for(let item in arr){
            if(arr[item].news_id){
              this.$set(arr[item],'id',arr[item].news_id)
              this.$Message.success('请根据下方文章指引进行投诉')
              setTimeout(()=>{
                this.Jump(arr[item])
              },1000)
              state = true
              break
            }
          }
          if(!state){
            this.$Message.success(`${res.msg}`)
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //弹幕开关
    handelsearchCommentForm(){
      this.commentForm = !this.commentForm
      if(this.commentForm){
        this.$refs.danmaku.show()
      }else{
        this.$refs.danmaku.hide()

      }
    },
    //提交弹幕评论
    submitComment(){
      if(this.token == ''){
        this.showLogin = true
        this.showdownload = false
        this.$Message.error('请登录后使用')
        return
      }
      if(this.comment == ''){
        return this.$Message.error('请填写评论内容')
      }
      let timer;
      if(this.isEditComment){
        return this.$Message.error('60秒后可继续评论')
      }
      let params = {
        share_id:this.$store.state.share_id || localStorage.getItem('share_id'),
        content:this.comment
      }
      sendComment(params).then(res=>{
        if(res.code == 1){
          this.comment = ''
          this.isEditComment = true
          setInterval(()=>{
            this.isEditComment = false
          },60000)
          this.$Message.success(`${res.msg}`)
        }
      })


    },
    //从我的云盘中提交数据
    submityunpanData(item){
      let arr = Array.isArray(item)?item:[]
      for(let item in this.fileList){
        if(this.fileList[item].isCheck){
          arr.push({
            name:this.fileList[item].name,
            size:this.fileList[item].size,
            suffix:this.fileList[item].type == 1 ? 'folder' :this.fileList[item].suffix,
            id:this.fileList[item].id,
            type:this.fileList[item].type,
          })
        }

      }
      // uploadTitle
      arr.forEach((item,index)=>{
        if(index == 0){
          this.uploadTitle = item.name
        }else{
          this.uploadTitle = arr[0].name + '等' + arr.length+'个文件'
        }
      })

      if(arr.length == 0){
        this.$Message.error('请选择文件')
        return false
      }
      let data = {
        files_list : arr
      }
      getSizeTotal(data).then(res=>{
        if(res.code == 1){
          this.fileAllSize = res.data.size_total
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })

      console.log('arr',arr)
      this.fileList1 = arr
      //提交的时候关闭 云盘弹窗
      this.cloudDiskVisible = false
      //提交的时候 打开文件传输窗口
      this.transferShow = true
      //提交的时候关闭首个窗口
      this.card  = false
      //关闭上传文件弹窗
      this.showSendFile2 = false
      this.yunpanObj.type = 1

      //切换数据保留之前的数据
      // this.fileList1 = this.yunpanObj.files
    },
    //生成分享链接的二维码图片
    createQRImg(id){
      let params = {
        share_id:id
      }
      createShareQr(params).then(res=>{
        this.share_qr = res.data.qr_img
        if(res.code == 0){
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //邮箱发送
    handelEmail(){
      let emails = []
      let emailsStr = ''
      for(let item in this.sendEmail){
        if(this.sendEmail[item].email != ''){
          emails.push(this.sendEmail[item].email)
          emailsStr = JSON.parse(JSON.stringify(emails.join(';')))
        }
      }
      let parmas = {
        share_id:this.share_id,
        emails:emailsStr
      }
      // Loading.service(options);
      this.shareEmailVisible = false
      sendOutEmail(parmas).then(res=>{
        if(res.code == 1){
          this.$Message.success(`${res.msg}`)
          // this.shareEmailVisible = false
           this.sendEmail = [{email:''}]
        }else{
          this.$Message.error(`${res.msg}`)
          // this.shareEmailVisible = true
        }
      })
    },

    //取件
  async  hadelCollect(url){
    this.getWechatLoginQrCode()
      this.getFileVal = typeof url == "string"?url:this.getFileVal
      this.showLogin = false
      if(this.getFileVal == '' && this.searchState == false){
        this.$Message.error('请输入取件链接或取件码')
        return false
      }
      let params = {
        share_url:'',
        pickup_pwd:this.getFileVal
      }
      this.commentForm = true
      this.showCommentForm = false
     await getFileInfo(params).then(  res=>{
        if(res.code == 0){
          this.showTake = true
          this.showdownLoadFile = false
          this.card = false
          this.b3 = true
          this.b1 = false
          this.b2 = false
          // this.$Message.error(`${res.msg}`)
        }else{
          this.reportObj = res.data
          this.backgroundList = []
          setTimeout(()=>{
            for(let item in this.reportObj.background_list){
              this.backgroundList.push({
                img:this.reportObj.background_list[item].img,
                url:this.reportObj.background_list[item].url
              })
            }
          },0)

          // this.$refs.mySwiper.update()
          // this.$set(this.swiperOption,'loop',true)
          this.$set(this.swiperOption,'observeParents',true)
          this.$set(this.swiperOption,'observer',true)
          let time = this.reportObj.background_time * 1000
          this.$set(this.swiperOption.autoplay,'delay',time)
          this.btn1 = false
          this.share_id = res.data.share_info.id
          this.showTake = true
          this.showdownLoadFile = true
          this.card = false
          // //弹幕数组为空的话 关闭弹幕
          if(this.reportObj.comment_list.length == 0){
            this.showCommentForm = false
            // this.$refs.danmaku.hide()
          }else{
            this.showCommentForm = true
          }
          //弹幕
          this.reportObj.comment_list.forEach(item=>{
            this.danmus.push({
              id:item.id,
              name:item.username,
              text: item.content,
              avatar:item.show_avatar,
              show_phone:item.show_phone,
              likes_num:item.likes_num,
              share_id:item.share_id,
              state:false
            })
          })
          //判断取件状态
          if(this.reportObj.share_info.need_pay == 1 && this.reportObj.pay_result == 0){
            this.b1 = false
            this.b2 = true
            this.b3 = false
            // this.handelPayUrl(this.share_id,1)
            console.log('this.share_id',this.share_id)
          }else{
            this.b1 = true
            this.b2 = false
            this.b3 = false
          }
          if(this.reportObj.list.length == 0){
            this.showdownLoadFile = false
            this.b1 = false
            this.b2 = false
            this.b3 = true
            this.showGetFile =  false
            return false
          }
          localStorage.setItem('share_id',this.share_id)
          this.folderList = this.reportObj.list
          //放入选择状态 默认等于 false
          for(let item in this.folderList){
            this.$set(this.folderList[item],'state',false)
            if(this.folderList[item].type == 1){
              this.$set(this.folderList[item],'suffix','folder')
            }
          }
        }
      })
    },
    //取件唤起支付弹窗
   async handelPayfile(){
      if(!this.token){
        this.$Message.error('请登录后使用')
        this.showLogin = true
      }else{
        await this.handelPayUrl()
        // this.showReportPay = true
      }
    },
    //取件支付
    handelPayUrl(id){
      let params = {
        share_id:id?id:this.share_id,
        pay_type:this.payIndex?this.payIndex:1,
      }
      sendBuyShare(params).then(res=>{
        console.log('支付res',res)
        if(res.code == 1){
          this.payObj = res.data
          this.showReportPay = true
          this.isPay()
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //关闭支付弹窗
    closeshowReportPay(){
      this.showReportPay = false
      window.clearInterval(this.timer)
    },
    isPay(){
        let params = {
          order_number:this.payObj.order_number
        }
        this.timer =  setInterval(()=>{
          checkLinkOrderPay(params).then(res=>{
            if(res.code == 1){
              this.$Message.success(`${res.msg}`)
              this.showReportPay = false
              this.reportObj.pay_result = 1
              this.b1 = true
              this.b2 = false
              this.b3 = false
              window.clearInterval(this.timer)
            }
          })
        },2000)


    },
    //取件全选
    handelDownloadClickAll(){
      if(this.isDownloadCheckAll){
        this.folderList.forEach(item=>item.state=false)
        this.btn1 = false
      }else{
        this.btn1 = true
        this.folderList.forEach(item=>item.state=true)
      }
    },
    //云盘全选
    handleClickAll(){
      // yunpanAllCheck
      this.$nextTick(()=>{
        this.fileList.forEach((item,index)=>{
          if(this.yunpanAllCheck){
            item.isCheck = true
            this.yunpanList.push(item)
            if(item.type == 1){
              item.suffix = 'folder'
            }
          }else{
            for (let data in this.yunpanList){
              if(this.yunpanList[data].id == item.id){
                this.yunpanList.splice(data ,1)
                item.isCheck = false
              }
            }

          }
        })
        this.yunpanList = Array.from(new Set(this.yunpanList))
        this.yunpanObj.files = this.yunpanList
      })
    },
    //云盘单选
    pushIsCheck(data){
      console.log('点击了吗')
      let arr = this.yunpanList
      this.$nextTick(()=>{
        if(data.isCheck){
          if(data.type == 1){
            data.suffix = 'folder'
          }
          this.yunpanObj.type = 1
          this.yunpanList.push(data)
          this.yunpanObj.files = this.yunpanList

        }else{
          arr.forEach((item,index)=>{
            if(data.id == item.id){
              arr.splice(index,1)
            }
          })
          this.yunpanList = arr
        }
        //如果当前页全部选择 就变为全选状态
        if(this.fileList.every(item=>item.isCheck === true)){
          this.yunpanAllCheck = true
        }else{
          this.yunpanAllCheck = false
        }
      })
    },
    //取件选择数据
    handelDownloadData(data){
      //每次获取数据时清空数组
       this.$nextTick(()=>{
         if(data.state){
           this.downLoadList.push(data)
           this.btn1 = true
         }else{
           for(let item in this.downLoadList){
             if(this.downLoadList[item].id == data.id){
               this.downLoadList.splice(item,1)
               break
             }
           }
         }
         if(this.downLoadList.length == 0){
           this.btn1 = false
         }
       })
     },
    //查询文件夹
    handelDownloadName(data,type,index){
      //type
      if(type == 1){
        //每次获取数据时清空数组
        this.downLoadList = []
        //每次获取数据重置按钮状态
        this.btn1 = false

        let params = {
          share_id:this.$store.state.share_id || localStorage.getItem('share_id'),
          pickup_pwd:this.getFileVal,
          prev_id:data.id,
          operate:1
        }
        getFileFolder(params).then(res=>{
          this.folderList = res.data.list
          for(let item in this.folderList){
            this.$set(this.folderList[item],'state',false)
            if(this.folderList[item].type == 1){
              this.$set(this.folderList[item],'suffix','folder')
            }
          }
          if(res.data.prev_id == null){
            this.downLoadTitle = []
            this.downLoadTitle.push({
              id:'',
              title:'传输详情'
            })
          }else{
            //选择头部信息
            let state = false
            for(let item in this.downLoadTitle){
              if(this.downLoadTitle[item].id == data.id) {
                state = true
                this.downLoadTitle.splice(index + 1,this.downLoadTitle.length - item)
              }
            }
            if(!state){
              this.downLoadTitle.push({
                id:data.id,
                title:data.name
              })
            }
          }

          //勾选回显
          for(let data in this.downLoadList){
            for(let item in this.folderList){
              if(this.downLoadList[data].id == this.folderList[item].id && this.downLoadList[data].state == true){
                this.folderList[item].state = this.downLoadList[data].state
              }
            }
          }

        })
      }
    },

    //获取真实下载地址
    getdownloadUrl(task_id){
      let data =  {}
     let closeInterVal =  setInterval(()=>{
       for(let item in this.packZipList){
         data.task_id = this.packZipList[item].task_id
         if(this.packZipList[item].states == 0){
           inquireTask(data).then( res=>{
             if(res.code == 1){
               this.$Message.success(`${res.msg}`)
               this.packZipList[item].states = 1
               this.packZipList[item].url = res.data.url
              //  window.open(`${this.packZipList[item].url}`, '_blank')
              var anchor = document.createElement('a');
              anchor.href = this.packZipList[item].url;
              anchor.download = this.packZipList[item].url;
              document.body.appendChild(anchor);
              anchor.click();
              document.body.removeChild(anchor);
               //每次下载完事 清空选择下载文件数组
               // this.folderList.filter(item=>{
               //    return item.state = false
               // })
               if(this.packZipList[item].freeDown == 0 ){
                 this.$notify({
                   title: `本次下载消耗${ this.flowType?'您':'发件者' }${  formatSize(this.packZipList[item].size) }流量`,
                   message: '文件即将开始下载，请保持网络通畅',
                   type: 'success',
                   duration:30000,
                   customClass:'notifyStyle',
                 });
               }else{
                 this.$notify({
                   title: '本次下载免流量',
                   message: '文件即将开始下载，请保持网络通畅',
                   type: 'success',
                   duration:30000,
                   customClass:'notifyStyle',
                 });
               }
               //下载完事 改变按钮状态
               this.btn1 = false
               console.log('文件下载数组',this.downLoadList)
               clearInterval(closeInterVal)
             }
           })

         }

       }
      },3000)

    },
    //单文件下载
    getFileloadUrl(task_id){
      let data = {
        task_id
      }
      inquireTask(data).then(res=>{
        if(res.code == 1){
          this.$fileDownload(res.data.file_name,res.data.url)
        }
      })
    },
    //评论点赞
    clickPraise(item){
      console.log('item',item)
      if(item.state) return false
      let params = {
        comment_id:item.id
      }
      clickComment(params).then(res=>{
        console.log('res')
        if(res.code == 1){
          item.likes_num = item.likes_num +1
          item.state = true
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })

    },
    handleshowSendFile2(){
      this.showSendFile2 = !this.showSendFile2
    },
    handleClose(e){
      this.show=false;
    },
    handleClose2(e){
      this.showSendFile=false;
    },
    handleClose3(e){
      this.showLogin=false;
    },
    handleClose4(e){
      this.showSendFile2=false;
    },
    //背景跳转链接
    toLink(item){
      if(item){
        window.open(item,"_blank");
      }
    },
    handleCurrentChange(val) {
        this.searchPage = val
        this.handleSearch()
    },
    //搜索
    handleSearch(page){
      // let homeInfo = JSON.parse(localStorage.getItem('home'))
      console.log('home',this.homeInfo)
      if(this.homeInfo.enabled_search == 0){
        return this.$Message.error('该功能处于关闭中,开放时间请留意官方公告!')
      }
      if(this.searchVal==''){
        return this.$Message.error('请输入要搜索的文件')
      }
      this.isNavShwo = false;
      this.showSearchResult=true;
      let params = {
        keywords:this.searchVal,
        page:this.searchPage,
        limit:5
      }
      search(params).then(res=>{
        if(res.code == 1){
          this.searchList = res.data.list;
          this.searchCount = res.data.count;
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },

    //文件滚动事件
    // scrollEvent(e){
    //   //变量scrollTop是滚动条滚动时，距离顶部的距离
    //     let scrollTop = e.target.scrollTop;
    //     //变量windowHeight是可视区的高度
    //     let windowHeight = e.target.clientHeight;
    //     //变量scrollHeight是滚动条的总高度
    //     let scrollHeight = e.target.scrollHeight;
    //     //滚动条到底部的条件
    //     if(scrollTop + windowHeight + 1 >= scrollHeight){
    //       this.searchPage += 1
    //         this.handleSearch()
    //     }
    // },
    checkFileInfo(data){
      this.searchState = true
      this.getFileVal = data.pwd
      this.hadelCollect()
    },
    handelCopy(){
      if(this.percentage == 100){
        this.$copyText('您有一份文件〔'+this.uploadTitle+'〕待查收，点击链接：'+ this.link + ' 或访问迅鸟快传官网https://xntransfer.com' +  '输入取件码：'+this.link_code+'查看。')
        this.$Message.success('复制成功')

      }else{
        this.$Message.error('链接生成中')
      }
    },
    onCopy(e) {
      this.$Message.success('复制成功')
    },
    onError(e) {
      if(this.copy1 == true){
          this.$Message.error('等链接生成后再使用复制功能')
      }
      if(this.copy2 == true){
        this.$Message.error('等取件码生成后再使用复制功能')
      }
    },
     //流量--
     reduce(){
      if(this.flow <= 1) {
        setTimeout(() => {
          this.flow = '1'
          this.isProvideFlow = false
          return false
        }, 0);
      }
      if(this.uploadBefore.visitor_upload == 1){
          this.$Message.error('请登录后使用')
        }else{
          if(this.userInfo.vip_status == 1){
            this.isProvideFlow= true
            this.flow--
          }else{
            this.showPrice = true
            this.$Message.error('该功能只对会员开放')
            this.flow = '1'
            setTimeout(()=>{
              this.showPrice = false
            },2000)
          }
        }
    },
    //流量++
    upReduce(){
      // const size = this.fileAllSize ? this.fileAllSize : this.fileSize;
      let size = null;
      if(this.fileAllSize) {
        size = this.fileAllSize
      }else{
        size = this.fileSize / 1000
      }
      let num = Math.floor((parseInt(this.uploadBefore.member_flow_over) / size))
      if(this.flow < num){
        if(this.uploadBefore.visitor_upload == 1){
          this.$Message.error('请登录后使用')
        }else{
          if(this.userInfo.vip_status == 1){
            this.isProvideFlow= true
            if(this.flow < 999) {
              this.flow++
            }else{
              this.$Message.error('您已超出流量次数上限，次数最多为999')
            }
          }else{
            this.showPrice = true
            this.$Message.error('该功能只对会员开放')
            this.flow = '1'
            setTimeout(()=>{
              this.showPrice = false
            },2000)
          }
        }
      }else{
        this.$Message.error('流量余额不足，请先充值')
      }
    },
    //提供流量
    handelProvideFlow(e){
     //判断用户是否开启提供流量
    //  let num = Math.floor((parseInt(this.uploadBefore.member_flow_over) /  (this.fileSize / 1024)))
    //  if(num < 0) {
    //   this.$Message.error('流量余额不足，请先充值')
    //   this.flow = '1'
    //  }
      if(this.uploadBefore.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        //判断是不是会员 1是会员 0不是会员
        if(this.userInfo.vip_status == 1){
          // this.showPrice = true
          this.isProvideFlow = true
          // this.flow = e.target.value.replace(/[^\d]/g, "");
          const filteredValue = e.target.value.replace(/[^\d]/g, "");
          const parsedValue = parseInt(filteredValue, 10);
          const finalValue = parsedValue >= 1 ? parsedValue : 1;
          this.flow = finalValue;
        }else{
          this.showPrice = true
          this.$Message.error('该功能只对会员开放')
          this.flow = '1'
          setTimeout(()=>{
            this.showPrice = false
          },2000)
        }
      }
    },
    gologin(){
      localStorage.setItem('token','')
      this.token = ''
      this.showHome = false
      location.reload()
    },
    //下载
    handelDownLoadFile(){
      //判断条件
      if(this.reportObj.share_info.look_type == 3) return  this.$Message.error('分享者已关闭下载功能')
      let size = 0
      this.handelIndex = 1
      let data = {
        files_list:[],
      }
      //文件只有一个的时候 直接下载
      if(this.folderList.length == 1 && this.folderList[0].type == 2){
          this.folderList.forEach(item=>{
            data.files_list.push({
              id:item.id,
              type:item.type
            })
          })
      }else{
        if(this.folderList.every(item=> item.state == false)){
          //判断弹窗状态
          if(!this.showdownload){
            this.showdownload = true
            this.showLogin = false
          }
          return  this.$Message.error('请先勾选需要下载的文件')
        }else{
          //下载勾选的文件
            this.folderList.forEach(item=>{
              if(item.state == true){
                data.files_list.push({
                  id:item.id,
                  type:item.type
                })
              }
            })
        }

        // //判断弹窗状态
        // if(!this.showdownload){
        //   this.showdownload = true
        //   this.showLogin = false
        // }
        // return  this.$Message.error('请先勾选需要下载的文件')


        // //多个的时候 需要选择下载 当为勾选文件时提示

      }
      let fileSizeTotal = this.getFileSizeTotal(data)
      fileSizeTotal.then(res=>{
        let resData = res.data
        this.fileSizeTotalInfo = res.data
        this.downLoadFileSize = resData.size_total
        this.freeDown = resData.free_down
        //获取勾选的数组
        let list = data.files_list
        //是否需要打包下载
        console.log('list',list)
        //文件数量超过1 需要打包
        if(list.length > 1){
          this.isZip = true
        }else{
          //判断单个文件的时候 是什么类型 2文件 1文件夹
          if(list[0].type == 2){
            this.isZip = false
          }else{
            this.isZip = true
          }
        }
        // //打包时间安装 每秒钟1.67M计算 1670KB
        let time = Math.ceil(this.downLoadFileSize / 1670)
        if(time > 60 && this.isZip &&  this.continueDownLoadTipsState){
          this.showContinueDownLoadTips = true
          // this.showdownLoadTips = false
          this.packZipTime = Math.ceil(time / 60)
          return  false
        }

        //游客状态下 文件是否小于免费提供的文件
        if(this.token == '' && resData.size_total < this.reportObj.share_info.free_flow_total){
          this.showdownLoadTips = false
          this.handelFile()
          this.flowType = false
          this.freeDown = 1
        }
        if(this.token == '' && resData.size_total > this.reportObj.share_info.free_flow_total){
          this.showLogin = true
          this.showdownload = false
          return this.$Message.error('请登录后使用')
        }


        //如果文件小于免下载流量 直接下载
        if(resData.size_total < resData.visitor_download_max ){
          this.showdownLoadTips = false
          this.handelFile()
          this.flowType = false
          this.freeDown = 1
        }

        //需要花费流量
        if(this.freeDown == 0){
          //免费使用流量大于选中文件的流量
          if(this.reportObj.share_info.free_flow_total >= this.downLoadFileSize){
            this.f1 = false
            this.f2 = false
            this.f3 = false
            this.showdownLoadTips = false
            this.flowType = false
            this.handelFile()
          }else{
            //用户剩余流量大于选中文件的流量
            if(this.reportObj.member_flow_over >= this.downLoadFileSize ){
              this.f1 = true
              this.f2 = false
              this.f3 = false
              this.flowType = true
              this.showdownLoadTips = true
            }else{
              //如果需要花费流量下载 需要登录
              if(this.token == ''){
                this.showdownLoadTips = false
                this.showLogin = true
                this.showdownload = false
                return  this.$Message.error('请登录后使用')
              }
              this.f1 = false
              this.f2 = false
              this.f3 = true
              this.flowType = true
              this.showdownLoadTips = true
            }
          }
        }else{
            this.handelFile()
        }
      })
    },
    //获取选择文件的大小
    getFileSizeTotal(data){
      return new Promise(resolve => {
         getSizeTotal(data).then(res=>{
          if(res.code == 1){
            resolve(res)
          }
        })
      })
    },
    //预览
    handelpreviewFile(data){
      this.previewFileObj = data
      this.handelIndex = 0;
      this.freePreview = 1
      //判断条件 是否支持预览
      if(this.reportObj.share_info.look_type == 2) return  this.$Message.error('分享者已关闭预览功能')

      if(this.token == ''){
        this.showLogin = true
        this.showdownload = false
        return this.$Message.error('请登录后使用')
      }


      if(
          data.suffix !== 'png' &&
          data.suffix !== 'jpg' &&
          data.suffix !== 'jpeg' &&
          data.suffix !== 'mp3' &&
          data.suffix !== 'mp4' &&
          data.suffix !== 'pdf'&&
          data.suffix !== 'docx' &&
          data.suffix !== 'doc'
      ) return this.$Message.error('此文件暂不支持在线预览，请下载后查看')

      if(data.size < this.homeInfo.preview_min){
        this.freePreview = 0
        this.handelFile()
        return false
      }

      this.showdownLoadTips = true;
      if(this.reportObj.share_info.free_flow_total >= this.previewFileObj.size){
        this.f1 = false
        this.f2 = true
        this.f3 = false
      }else{
        if(this.reportObj.member_flow_over >= this.previewFileObj.size ){
          this.f1 = false
          this.f2 = true
          this.f3 = false
        }else{
          this.f1 = false
          this.f2 = false
          this.f3 = true
        }

      }
    },

    //文件预览 0  / 文件下载 1
    handelFile(){
      if(this.handelIndex == 0){
        this.isNavShwo = true
        let params = {
          share_id :this.share_id || this.$store.state.share_id || localStorage.getItem('share_id'),
          file_id : this.previewFileObj.id,
        }
        previewFile(params).then( async res=>{
          this.showdownLoadTips = false
          if(res.code == 1){
            // window.open(`${res.data.file_url}`, '_blank')
            this.fileUrl = await  res.data.file_url
            this.fileType =  await res.data.file_suffix
            this.showPreviewFile = true
            if(this.freePreview == 0){
              this.$notify({
                title: `本次预览免流量`,
                message: '现在您可以在线预览该文件了',
                type: 'success',
                duration:30000,
              });
            }else{
              this.$notify({
                title: `消费${formatSize(this.previewFileObj.size)}流量成功`,
                message: '现在您可以在线预览该文件了',
                type: 'success',
                duration:30000
              });
            }

            //扣除预览浏览
            this.reportObj.member_flow_over = this.reportObj.member_flow_over - this.previewFileObj.size
          }else{
            this.$Message.error(`${res.msg}`)
          }
        })
      }else{
        this.isNavShwo = true
        if(!this.isWeChatPC()){
          let arr  = []
        let data = {
          share_id:this.share_id || this.$store.state.share_id || localStorage.getItem('share_id'),
          files_list:[],
        }
        if(this.folderList.every(item=>item.state === false)){
          data.files_list = this.folderList.filter(item=>{
            arr.push({
              id:item.id,
              type:item.type,
            })
            return  arr
          })
        }else{
          data.files_list = this.folderList.filter(item=>{
            if(item.state == true){
              arr.push({
                id:item.id,
                type:item.type,
              })
              return  arr
            }
          })
          data.files_list = arr
        }
        this.$store.commit('handelDownLoadData',{
          totalSize:this.downLoadFileSize,//总大小
          downLoadList:data.files_list,//数据
          shareId:data.share_id,//分享链接id
          flowOverTotal:this.userInfo.flow_over_total,//用户流量
          visitorDownloadMax:this.fileSizeTotalInfo.visitor_download_max,//免流量下载最大限制
          freeDown:this.freeDown,
          isZip:this.isZip,
          time:this.packZipTime,
          type:'home',
          flowType:this.flowType
        })
        }else{
          this.$Message.error('当前浏览器不支持大文件分发，请使用其它浏览器下载')
        }
      }
    },
    //关闭弹窗
    closePopup(){
      this.downLoadMsg = this.$store.state.download_msg
      this.downLoadType = this.$store.state.download_type
      this.showdownLoadTips = false
      this.showdownload = false
      this.showTake = false
      this.card = false
      this.showGetFile=false
      // this.getFileVal = ''
      this.downloadAfter = true
      this.showLogin = false
      this.showSearchResult = false
    },
    //转存
    handelRedeposit(){
      //未登录状态下不可转存
      if(this.token == '') return  this.$Message.error('请登录')
      if(this.reportObj.share_info.look_type == 3) return  this.$Message.error('分享者已关闭转存功能')
      // if(this.btn1 == false)  return  this.$Message.error('请选择文件')

      //是否为单个文件 单个文件直接 打开转存弹窗
      if(this.folderList.length == 1 && this.folderList.some(item=> item.type == 2)){
        this.popup = true
        this.popuptitle = '转存到'
        return  false
      }
      //如果是文件夹 提示错误
      if(this.folderList.length == 1 && this.folderList.some(item=> item.type == 1)){
        if(!this.showdownload){
          this.showdownload = true
        }
        return  this.$Message.error('请先勾选需要转存的文件（非文件夹）')
      }
      let showTips = false
      for(let item in this.folderList){
        if(this.folderList[item].state == true  && this.folderList[item].type == 1){
          showTips = true
        }
      }
      if(showTips){
        this.showdownload = true
          this.$Message.error('请先勾选需要转存的文件（非文件夹）')
      }else{
        if(this.folderList.every(item=>item.state == false)){
          this.showdownload = true
          this.$Message.error('请先勾选需要转存的文件（非文件夹）')
        }else{
            this.popup = true
            this.popuptitle = '转存到'
        }
      }
      // if(!showTips && this.folderList.every(item=>item.state == false)){
      //   this.$Message.error('请先勾选需要转存的文件（非文件夹）')
      // }else{
      //   console.log('执行的是这个吗')
      //   this.popup = true
      //   this.popuptitle = '转存到'
      // }
    },
    //转存弹窗操作
    handelPopupFile(e){

      let data = {
        share_id:this.share_id || this.$store.state.share_id || localStorage.getItem('share_id'),
        to_folder_id:e.data.id == '根目录' ?null:e.data.id,
        files_list:[]
      }

      if(this.folderList.length == 1 && this.folderList.every(item=>item.state == false) && this.folderList.some(item=>item.type == 2)){
        this.folderList.forEach(item=>{
            data.files_list.push(item)
        })
      }else{
        this.folderList.forEach(item=>{
          if(item.type == 2 && item.state){
            data.files_list.push(item)

          }
        })
      }
      this.popup = false
      this.$_throttle(
          saveToDisk(data).then(res=>{
            if(res.code == 1){
              this.$Message.success(`${res.msg}`)
              this.popup = false
            }else{
              this.$Message.error(`${res.msg}`)
            }
            console.log('res',res)
          }),
          0
      )

    },
    //搜索权限
    handelsearchPermission(){
      // 1是游客 0不是游客
      if(this.uploadBefore.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        //判断是不是会员 1是会员 0不是会员
        this.searchPermission=!this.searchPermission
      }

    },
    //定价
    handelPrice(){
      // 1是游客 0不是游客
      if(this.uploadBefore.visitor_upload == 1){
        this.$Message.error('请登录后使用')
      }else{
        //判断是不是会员 1是会员 0不是会员
        if(this.userInfo.vip_status == 1){
          // this.showPrice = true
          this.showPrice1 = false
        }else{
          this.showPrice = !this.showPrice
          this.timer = setTimeout(()=>{
            this.showPrice = false
          },4000)
          if(this.showPrice) return   this.$Message.error('该功能只对会员开放')

        }
      }
    },

    //跳转协议
    toAgree(data){
      console.log('data',data)
      this.$router.push({
        path:'/advice',
        name:'Advice',
        params:{
          id:data.id
        }
      })
    },
    //跳转
    Jump(item){
      console.log('item',item)
      if(this.uploading) return false
      if(item.jump_type == 1){
        window.open(item.url,"_blank");
      }else{
        this.$router.push({
          path:'/advice',
          name:'Advice',
          params:{
            id:item.news_id
          }
        })
      }
    },

    checkMailToAddress(callback) {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})(,([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4}))*$/;
      var str = 'xxxx@126.com;123234234@qq.com;xxxxx@xxxxx.con.cn';
      // this.mailToAddress = str.split(';');
      console.log('mailToAddress',this.mailToAddress)
      // if(this.mailToAddress ==''||this.mailToAddress==undefined||this.mailToAddress==null){
      //   callback();
      // } else {
      //   if (reg.test($.trim(this.mailToAddress))) {
      //     var l = this.mailToAddress.length;
      //     for (var i = 0; i < l; i++) {
      //       for (var j = i + 1; j < l; j++) {
      //         if (this.mailToAddress[i] === this.mailToAddress[j]) {
      //           i++;
      //           j = i;
      //           callback(new Error("存在重复邮箱地址，请更换"));
      //         }
      //       }
      //     }
      //     callback();
      //   } else {
      //     callback(new Error("请输入正确的邮箱地址"));
      //   }
      // }
    },
    //取件
    openPickup(){
      // this.showdownload = false

      // this.checkMailToAddress()
      if(this.uploading) return false
      this.showTake = false
      this.card = true
      this.showGetFile = false
      this.downloadAfter = false
      this.getFileVal = ''
      this.transferShareVisible = false
      this.transferShow = false
      this.cloudDiskVisible = false
      this.showCommentForm = false
      // this.$refs.danmaku.hide()
      this.getHomeData()
    },
    //返回取件
   async gobackPickUp(){
     await this.hadelCollect(this.getFileVal)
      this.showTake = true
      this.searchState = true
      this.showdownLoadFile = true
      this.card = false
      this.downloadAfter = false
     this.showLogin = false
     this.showSearchResult = false
     // this.showContinueDownLoadTips = true
     this.continueDownLoadTipsState = true
     this.downLoadTitle = [{id:'', title:'传输详情'}]

     // downLoadTitle:[{id:'', title:'传输详情'},],//下载头部名称
    },
    //发件
    openupLoad(){
      if(this.uploading) return false
      this.transferShareVisible = false
      this.card = true
      this.transferShow = false
      this.cloudDiskVisible = false
      this.showTake = false
      this.showGetFile = false
      this.getFileVal = ''
      this.getHomeData()
    },
    //生成海报图片并且下载到本地
    downloadImg(){
      let params = {
        share_id:this.share_id
      }
      this.shareQrVisible = false
      downloadQrImage(params).then(res=>{
        if(res.code == 1){
          let posterUrl = res.data.share_img_url64
          this.$fileDownload('',posterUrl,'1')
          this.$Message.success(`${res.msg}`)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    toShop(){
      // this.$store.state.shopType = index
      this.$router.push({
        path:'/shop',
        name:'Shop',
        params:{
          index:3
        }
      })
    },
    ldc(data){
      console.log('data',data)
    },
  },
};
</script>

<style>
/deep/.el-notification__title{
  white-space: nowrap !important;
}
.el-pager li{
  background-color:transparent !important;
  font-size: 0.1rem;
  height: 0.2rem;
  color: #fff !important;
}
.el-pagination .btn-prev,.el-pagination .btn-next{
  padding: 0 !important;
}
.el-pagination .btn-next, .el-pagination .btn-prev{
  background: linear-gradient(143deg, #22deb5 0%, #07a9ed 100%) !important;
  color: #fff;
}
.el-pagination button:disabled{
  background: rgba(255, 255, 255, 0.8) !important;
}
.el-pagination{
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.el-pager li.active{
  color: #22deb5 !important;
}
.el-pager li:hover{
  opacity: .8;
}
</style>

<style lang='less' scoped>
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #666666 !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    #666666 !important;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    #666666 !important;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    #666666 !important;
}
//@import "@assets/css/profile/account/myFiles.less";
@import "@assets/css/home.less";
</style>