import request from "@utils/request";
//资讯列表
export const getNewsList = ()=>{
    return request({
        url:'index/index/getNewsList',
        method:'post',
    })
}
//咨询详情
export const getNewsInfo = (params)=>{
    return request({
        url:'index/index/getNewsInfo',
        method:'post',
        params
    })
}
